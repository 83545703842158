import { ThemeProvider } from "@mui/material/styles";
import { ThemeProvider as VeneerThemeProvider } from "@veneer/theme";
import React, { createContext, useContext, useEffect, useState } from "react";
import { createDynamicTheme } from "./theme";
import wexTheme from "./wexTheme";

const themeAME = "theme-AME-mode";

const ModeContext = createContext();

export const useMode = () => useContext(ModeContext);

const UnifiedThemeProvider = ({ children, mode }) => {
  const theme = createDynamicTheme(mode);

  return (
    <ThemeProvider theme={theme}>
      <VeneerThemeProvider
        customSemantics={wexTheme}
        mode={mode}
        shape={"round"}
      >
        {children}
      </VeneerThemeProvider>
    </ThemeProvider>
  );
};

export const ModeProvider = ({ children }) => {
  const currentTheme = localStorage.getItem(themeAME) || "light";
  const [mode, setMode] = useState(currentTheme);

  const changeTheme = (newTheme) => {
    setMode(newTheme);
    localStorage.setItem(themeAME, newTheme);
  };

  useEffect(() => {
    changeTheme(currentTheme);
  }, []);

  return (
    <ModeContext.Provider value={{ mode, changeTheme }}>
      <UnifiedThemeProvider mode={mode}>{children}</UnifiedThemeProvider>
    </ModeContext.Provider>
  );
};
