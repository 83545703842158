import { Grid } from "@mui/material";
import { Button, IconCopy, useToast } from "@veneer/core";
import "@veneer/styles/typography/typography.css";
import PropTypes from "prop-types";

const CopyInfo = ({
  description,
  message,
  allowed = true,
  renderButton = true,
  customValue,
  dataTestId = "copy-info",
}) => {
  const { addToast } = useToast();

  const copyValue = (value) => {
    navigator.clipboard.writeText(value);

    addToast({
      text: message || "Copied to the clipboard",
      type: "positive",
      timeout: 6,
      id: Date.now(),
    });
  };

  const testId = dataTestId.replace(/\s/g, "-").toLowerCase();

  return (
    <Grid container data-testid={dataTestId}>
      {description && (
        <Grid item alignSelf="center">
          <span
            style={{
              display: "block",
              margin: 0,
              padding: 0,
            }}
            data-testid={`${testId}-description`}
          >
            {description}
          </span>
        </Grid>
      )}

      {renderButton && (
        <Grid item ml={0.5}>
          <Button
            style={{ padding: 0 }}
            leadingIcon={<IconCopy />}
            onClick={
              allowed ? () => copyValue(customValue || description) : undefined
            }
            small
            appearance="ghost"
            disabled={!allowed}
            data-testid={`${testId}-cp-button`}
          />
        </Grid>
      )}
    </Grid>
  );
};

CopyInfo.propTypes = {
  description: PropTypes.node,
  message: PropTypes.string,
  allowed: PropTypes.bool,
  renderButton: PropTypes.bool,
  customValue: PropTypes.string,
  dataTestId: PropTypes.string,
};

export default CopyInfo;
