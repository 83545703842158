import { createSlice } from "@reduxjs/toolkit";
import { ADMIN_TABLE_ACCORDIAN } from "../../dashboard/dashboardConstants";
import {
  ACCORDIAN_KEYS,
  CI_STATUS_TYPES,
  LOGS_CONFIG_KEYS,
} from "../constants";
import { fetchItem, setItem } from "./../../storage";

// logs initial values
const INITIAL_LOGS_QUERY = JSON.parse(fetchItem("logsQuery"))?.[
  LOGS_CONFIG_KEYS.TENANT_LOGS
] || {
  query: "SPOC_CL",
  timespan: "PT24H",
  severity: " ",
  search: "",
  sortBy: "Timestamp_t",
};
const INITIAL_ALL_ADMIN_ACTIVITY_LOGS = JSON.parse(fetchItem("logsQuery"))?.[
  LOGS_CONFIG_KEYS.ALL_ADMIN_ACTIVITY_LOGS
] || { timespan: "60m", sortBy: "timestamp", tenant: "-1" };
const INITIAL_TENANT_ACTIVITY_LOGS = JSON.parse(fetchItem("logsQuery"))?.[
  LOGS_CONFIG_KEYS.TENANT_ADMIN_ACTIVITY_LOGS
] || { timespan: "60m", sortBy: "timestamp" };
const INITIAL_USER_LOGON_ACTIVITY_LOGS_USER_SESSION = JSON.parse(
  fetchItem("logsQuery"),
)?.[LOGS_CONFIG_KEYS.USER_LOGON_ACTIVITY_FOR_USER_SESSION] || {
  timespan: "60m",
  sortBy: "InitiatedTime",
};
const INITIAL_USER_LOGON_ACTIVITY_LOGS_TENANT = JSON.parse(
  fetchItem("logsQuery"),
)?.[LOGS_CONFIG_KEYS.USER_LOGON_ACTIVITY_FOR_TENANT] || {
  timespan: "60m",
  sortBy: "InitiatedTime",
};
const INITIAL_USER_LOGON_ACTIVITY_LOGS_MACHINE = JSON.parse(
  fetchItem("logsQuery"),
)?.[LOGS_CONFIG_KEYS.USER_LOGON_ACTIVITY_FOR_MACHINE] || {
  timespan: "60m",
  sortBy: "InitiatedTime",
};
const INITIAL_CI_ALERTS_CONFIG = JSON.parse(fetchItem("logsQuery"))?.[
  LOGS_CONFIG_KEYS.CI_ALERTS_CONFIG
] || {
  timespan: "ONE_DAY",
  status: CI_STATUS_TYPES.ACTIVE,
};

// accordians initial values
const INITIAL_ACCORDIAN_POSITIONS = {
  [ACCORDIAN_KEYS.ADMINS_LIST]: ADMIN_TABLE_ACCORDIAN,
};

export const initialState = {
  logsQuery: {
    [LOGS_CONFIG_KEYS.TENANT_LOGS]: INITIAL_LOGS_QUERY,
    [LOGS_CONFIG_KEYS.TENANT_ADMIN_ACTIVITY_LOGS]: INITIAL_TENANT_ACTIVITY_LOGS,
    [LOGS_CONFIG_KEYS.USER_LOGON_ACTIVITY_FOR_USER_SESSION]:
      INITIAL_USER_LOGON_ACTIVITY_LOGS_USER_SESSION,
    [LOGS_CONFIG_KEYS.USER_LOGON_ACTIVITY_FOR_TENANT]:
      INITIAL_USER_LOGON_ACTIVITY_LOGS_TENANT,
    [LOGS_CONFIG_KEYS.USER_LOGON_ACTIVITY_FOR_MACHINE]:
      INITIAL_USER_LOGON_ACTIVITY_LOGS_MACHINE,
    [LOGS_CONFIG_KEYS.CI_ALERTS_CONFIG]: INITIAL_CI_ALERTS_CONFIG,
    [LOGS_CONFIG_KEYS.ALL_ADMIN_ACTIVITY_LOGS]: INITIAL_ALL_ADMIN_ACTIVITY_LOGS,
  },
  accordianPositions:
    JSON.parse(fetchItem("accordianPositions")) || INITIAL_ACCORDIAN_POSITIONS,
  tableSortPositions: JSON.parse(fetchItem("tableSortPositions")) || {},
  ciMetricTimeInterval: JSON.parse(fetchItem("ciMetricTimeInterval")) || {},
  tableColumnsPrefreferences:
    JSON.parse(fetchItem("tableColumnsPrefreferences")) || {},
  showFullScreen: false,
};

const userPreferencesSlice = createSlice({
  name: "userPreferences",
  initialState,
  reducers: {
    setLogsQuery: (state, action) => {
      state.logsQuery[action.payload.logsKey] = action.payload.config;
      setItem("logsQuery", JSON.stringify(state.logsQuery));
    },
    setAccordianPositions: (state, action) => {
      state.accordianPositions[action.payload.accordianKey] =
        action.payload.accordianValue;
      setItem("accordianPositions", JSON.stringify(state.accordianPositions));
    },
    setTableSortConfig: (state, action) => {
      state.tableSortPositions[action.payload.tableKey] =
        action.payload.sortConfig;
      setItem("tableSortPositions", JSON.stringify(state.tableSortPositions));
    },
    setShowFullScreen: (state, action) => {
      state.showFullScreen = action.payload.showFullScreen;
    },
  },
  extraReducers: {},
});

export const {
  setLogsQuery,
  setTableSortConfig,
  setAccordianPositions,
  setShowFullScreen,
} = userPreferencesSlice.actions;

export default userPreferencesSlice.reducer;
