import { COMPONENT_NAMES } from "./constants";

export const PERMISSION_DENIED_MESSAGE =
  "You don’t have permission to access this. Contact your IT Administrator to request access.";

export const ORG_ROLES = {
  ORG_ADMIN: "Organization Administrator",
  ORG_USER: "Organization User",
  TENANT_CREATOR: "Tenant Creator",
  TENANT_ADMIN: "Tenant Administrator",
  SUPPORT_USER: "Support User",
};

export const DEFAULT_PERMISSIONS = {
  [ORG_ROLES.ORG_ADMIN]: [
    COMPONENT_NAMES.ADD_TENANT,
    COMPONENT_NAMES.ORG_DETAIL,
    COMPONENT_NAMES.TENANT_ACTIONS,
  ],
  [ORG_ROLES.ORG_USER]: [],
  [ORG_ROLES.TENANT_CREATOR]: [
    COMPONENT_NAMES.ADD_TENANT,
    COMPONENT_NAMES.ORG_DETAIL,
    COMPONENT_NAMES.TENANT_ACTIONS,
  ],
  [ORG_ROLES.TENANT_ADMIN]: [
    COMPONENT_NAMES.ADD_TENANT,
    COMPONENT_NAMES.ORG_DETAIL,
    COMPONENT_NAMES.TENANT_ACTIONS,
  ],
};

const ROLE_PERMISSIONS = {
  [ORG_ROLES.ORG_ADMIN]: {
    createTenants: true,
    deleteTenants: true,
    accessSettings: true,
    manageUsers: true,
    requiresTenantAccess: false,
  },
  [ORG_ROLES.ORG_USER]: {
    createTenants: false,
    deleteTenants: false,
    accessSettings: false,
    manageUsers: false,
    requiresTenantAccess: true,
  },
  [ORG_ROLES.TENANT_ADMIN]: {
    createTenants: true,
    deleteTenants: true,
    accessSettings: false,
    manageUsers: false,
    requiresTenantAccess: false,
  },
  [ORG_ROLES.SUPPORT_USER]: {
    createTenants: false,
    deleteTenants: false,
    accessSettings: false,
    manageUsers: false,
    requiresTenantAccess: true,
  },
};

export const hasLimitedTenantAccess = (role) => {
  return role === ORG_ROLES.ORG_USER || role === ORG_ROLES.SUPPORT_USER;
};

// Functions to check permissions based on roles
// Only to use in the /admin page
export const canCreateTenants = (role) => {
  return ROLE_PERMISSIONS[role]?.createTenants || false;
};

export const canDeleteTenants = (role) => {
  return ROLE_PERMISSIONS[role]?.deleteTenants || false;
};

export const canAccessSettings = (role) => {
  return ROLE_PERMISSIONS[role]?.accessSettings || false;
};

export const canManageUsers = (role) => {
  return ROLE_PERMISSIONS[role]?.manageUsers || false;
};
