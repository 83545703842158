import { styled } from "@mui/material";
import { IconPencil, Card as VeneerCard } from "@veneer/core";
import { isValidElement } from "react";
import CopyInfo from "./CopyInfo";
import NoDataDisplayCard from "./NoDataDisplayCard";
import VeneerButton from "./VeneerButton";

const PREFIX = "CardDetails";

const baseLabelStyles = (theme) => ({
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  fontFamily: "Forma DJR UI",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "24px",
  color: theme.palette.foreground.light,
});

const classes = {
  content: `${PREFIX}-content`,
  title: `${PREFIX}-title`,
  divider: `${PREFIX}-divider`,
  details: `${PREFIX}-details`,
  detail: `${PREFIX}-detail`,
  label: `${PREFIX}-label`,
  boldLabel: `${PREFIX}-boldLabel`,
  labelKey: `${PREFIX}-labelKey`,
  labelValue: `${PREFIX}-labelValue`,
  titleContainer: `${PREFIX}-titleContainer`,
  titleInnerContainer: `${PREFIX}-titleInnerContainer`,
  subtitle: `${PREFIX}-subtitle`,
  detailsGrid: `${PREFIX}-detailsGrid`,
  detailsRow: `${PREFIX}-detailsRow`,
  labelContainer: `${PREFIX}-labelContainer`,
  valueContainer: `${PREFIX}-valueContainer`,
  copyContainer: `${PREFIX}-copyContainer`,
  columnContainer: `${PREFIX}-columnContainer`,
};

const CardDetailsRoot = styled("div")(({ theme }) => ({
  width: "100%",
  minHeight: "100px",
  overflowX: "auto",
  overflowY: "auto",

  [`& .${classes.content}`]: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    padding: "16px 24px",
  },

  [`& .${classes.titleContainer}`]: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "24px",
  },

  [`& .${classes.title}`]: {
    ...baseLabelStyles(theme),
    fontSize: "20px",
    fontWeight: 700,
    color: theme.palette.foreground.default,
  },

  [`& .${classes.boldLabel}`]: {
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "20px",
    margin: 0,
    color: theme.palette.foreground.default,
  },

  [`& .${classes.titleInnerContainer}`]: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },

  [`& .${classes.subtitle}`]: {
    ...baseLabelStyles(theme),
    fontSize: "16px",
    fontWeight: 400,
    color: theme.palette.foreground.light,
  },

  [`& .${classes.divider}`]: {
    width: "100%",
    backgroundColor: theme.palette.background.stroke,
    height: "1px",
    margin: "8px 0",
  },

  [`& .${classes.details}`]: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    alignSelf: "stretch",
    borderBottom: `solid 1px ${theme.palette.background.stroke}`,
    height: "40px",
    ["&:last-child"]: {
      borderBottom: "none",
    },
  },

  [`& .${classes.detailsGrid}`]: {
    display: "grid",
    gridGap: "40px",
    width: "100%",
  },

  [`& .${classes.columnContainer}`]: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    width: "100%",
  },

  [`& .${classes.detailsRow}`]: {
    display: "grid",
    gridTemplateColumns: (isCopiable) =>
      isCopiable ? "35% 1fr 30px" : "35% 1fr",
    alignItems: "center",
    borderBottom: `solid 1px ${theme.palette.background.stroke}`,
    height: "40px",
    "&:last-child": {
      borderBottom: "none",
    },
  },

  [`& .${classes.labelContainer}`]: {
    gridColumn: "1",
    overflow: "hidden",
    paddingLeft: "4px",
  },

  [`& .${classes.valueContainer}`]: {
    gridColumn: "2",
    overflow: "hidden",
    paddingRight: "8px",
    textAlign: "right",
  },

  [`& .${classes.copyContainer}`]: {
    gridColumn: "3",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "30px",
  },

  [`& .${classes.detail}`]: {
    display: "flex",
    flexDirection: "column",
  },

  [`& .${classes.label}`]: {
    ...baseLabelStyles(theme),
  },

  [`& .${classes.labelKey}`]: {
    ...baseLabelStyles(theme),
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "20px",
  },

  [`& .${classes.labelValue}`]: {
    ...baseLabelStyles(theme),
    color: theme.palette.foreground.default,
    textAlign: "right",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
  },

  "& a": {
    color: theme.palette.primary.main,
    "&:hover": {
      color: theme.palette.primary.hover,
      textDecoration: "none",
    },
  },
}));

const StyledCard = styled(VeneerCard)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  borderRadius: "12px",
  border: `1px solid ${theme.palette.background.stroke}`,
}));

const CardDetails = ({
  title,
  subtitle,
  details,
  isCopiable = false,
  isEditable = false,
  onAddClick = () => {},
  numColumns = 1,
  style,
}) => {
  const renderDetails = () => {
    const filteredDetails = details.filter((detail) => detail.value);

    const columns = Array.from({ length: numColumns }, () => []);

    filteredDetails.forEach((detail, index) => {
      const columnIndex = index % numColumns;
      columns[columnIndex].push(detail);
    });

    const DetailsRow = styled("div")(({ theme }) => ({
      display: "grid",
      gridTemplateColumns: isCopiable ? "35% 1fr 30px" : "35% 1fr",
      alignItems: "center",
      borderBottom: `solid 1px ${theme.palette.background.stroke}`,
      height: "40px",
      "&:last-child": {
        borderBottom: "none",
      },
    }));

    return (
      <div
        className={classes.detailsGrid}
        style={{
          gridTemplateColumns: `repeat(${numColumns}, 1fr)`,
        }}
      >
        {columns.map((columnItems, colIndex) => (
          <div key={`column-${colIndex}`} className={classes.columnContainer}>
            {columnItems.map((detail, index) => (
              <DetailsRow key={`row-${colIndex}-${index}`}>
                <div className={classes.labelContainer}>
                  <p
                    className={
                      detail?.boldLabel
                        ? classes.boldLabel
                        : `${classes.label} ${classes.labelKey}`
                    }
                  >
                    {detail.label}
                  </p>
                </div>
                <div className={classes.valueContainer}>
                  {isValidElement(detail.value) ? (
                    detail.value
                  ) : (
                    <p className={`${classes.label} ${classes.labelValue}`}>
                      {detail.value}
                    </p>
                  )}
                </div>
                {isCopiable && detail.value && (
                  <div className={classes.copyContainer}>
                    <CopyInfo
                      dataTestId={String(detail.label)}
                      message={`${detail.label} copied to the clipboard`}
                      customValue={detail.value}
                    />
                  </div>
                )}
              </DetailsRow>
            ))}
          </div>
        ))}
      </div>
    );
  };

  return (
    <CardDetailsRoot style={style}>
      {title && (
        <div className={classes.titleContainer}>
          <div className={classes.titleInnerContainer}>
            <p className={classes.title}>{title}</p>
            {subtitle && <p className={classes.subtitle}>{subtitle}</p>}
          </div>
          {isEditable && (
            <VeneerButton
              appearance="secondary"
              leadingIcon={<IconPencil />}
              small
              style={{ border: "none", marginLeft: "8px" }}
              onClick={onAddClick}
            >
              Edit
            </VeneerButton>
          )}
        </div>
      )}
      <StyledCard
        background="filled"
        border="outlined"
        content={
          details?.length > 0 ? (
            <div className={classes.content}>{renderDetails()}</div>
          ) : (
            <NoDataDisplayCard title={title} onAddClick={onAddClick} />
          )
        }
      />
    </CardDetailsRoot>
  );
};

export default CardDetails;
