/* eslint-disable camelcase */
import {
  MODULES_LIST,
  PERMISSIONS_LIST,
  PERMISSIONS_TYPE,
} from "../common/constants";
import {
  FINALIZING_STATUS,
  INITIALIZING_STATUS,
} from "../onboarding/onboardingConstants";

export const CPU_USAGE_WARNING_THRESHOLD = 75;
export const CPU_USAGE_CRITICAL_THRESHOLD = 90;

export const LOG_ANALYTICS_RESOURCE_TYPE = "LogAnalytics";

export const CPU_PEAK_USAGE_WARNING_THRESHOLD = 75;
export const CPU_PEAK_USAGE_CRITICAL_THRESHOLD = 90;

export const PQL_WARNING_THRESHOLD = 4;
export const PQL_CRITICAL_THRESHOLD = 6;

export const ACTIVE_USERS_WARNING_THRESHOLD = 10;
export const ACTIVE_USERS_CRITICAL_THRESHOLD = 14;

export const RAM_USAGE_WARNING_THRESHOLD = 75;
export const RAM_USAGE_CRITICAL_THRESHOLD = 90;

export const RAM_PEAK_USAGE_WARNING_THRESHOLD = 75;
export const RAM_PEAK_USAGE_CRITICAL_THRESHOLD = 90;

export const LOGOUT_INTERVALS = [
  {
    value: 15,
    name: "15 Minutes",
  },
  {
    value: 60,
    name: "1 Hour",
  },
  {
    value: 120,
    name: "2 Hours",
  },
  {
    value: 180,
    name: "3 Hours",
  },
  {
    value: 240,
    name: "4 Hours",
  },
  {
    value: 300,
    name: "5 Hours",
  },
  {
    value: 360,
    name: "6 Hours",
  },
];

export const RESOURCE_HEALTH_STATUS = {
  HEALTHY: "healthy",
  UNHEALTHY: "unhealthy",
  NOT_APPLICABLE: "notapplicable",
};

export const RESOURCE_DETAILS_HEADINGS = {
  subscriptions: "Subscription",
  resourcegroups: "Resource Group",
  providers: "Provider",
  virtualmachines: "Virtual Machine",
};

export const SELECT_TENANT_TYPE = "/selectService";
export const SELECT_SERVICE_SITE_TYPE = "/sites/selectService";

export const TENANT_NOT_ACTIVE_STATUSES = [
  INITIALIZING_STATUS,
  FINALIZING_STATUS,
];

export const ORGANIZATION_TIMESPAN_OPTIONS = [
  { name: "Last 15 Minutes", value: "15m" },
  { name: "Last 30 Minutes", value: "30m" },
  { name: "Last 60 Minutes", value: "60m" },
  { name: "Last 2 Hours", value: "2h" },
  { name: "Last 3 Hours", value: "3h" },
  { name: "Last 6 Hours", value: "6h" },
  { name: "Last 12 Hours", value: "12h" },
  { name: "Last 24 Hours", value: "1d" },
  { name: "Past 48 Hours", value: "2d" },
  { name: "Past 1 Week", value: "7d" },
  { name: "Past 30 Days", value: "30d" },
];

export const ADMIN_TABLE_ACCORDIAN = "AdminTableAccordian";
export const ADMIN_ACTIVITY_LOGS_ORDER = [
  "UserName",
  "url",
  "name",
  "timestamp",
  "resultCode",
  "success",
  "duration",
];

export const ADMIN_ACTIVITY_LOGS_HEADER = {
  UserName: "Username",
  url: "Url",
  name: "Action",
  timestamp: "Timestamp",
  resultCode: "ResultCode",
  success: "Success",
  duration: "Duration(ms)",
};

export const ADMIN_CONFIG_ACTIONS_PERMISSIONS_SET = [
  PERMISSIONS_LIST[MODULES_LIST.CUSTOM_ACTIONS][PERMISSIONS_TYPE.VIEW],
  PERMISSIONS_LIST[MODULES_LIST.CUSTOM_ACTIONS][PERMISSIONS_TYPE.CREATE],
  PERMISSIONS_LIST[MODULES_LIST.CUSTOM_ACTIONS][PERMISSIONS_TYPE.EDIT],
  PERMISSIONS_LIST[MODULES_LIST.CUSTOM_ACTIONS][PERMISSIONS_TYPE.DELETE],
];
