import { StyledEngineProvider } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { ToastProvider } from "@veneer/core";
import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import App from "./App";
import { ModeProvider } from "./ModeProvider";
import ErrorBoundary from "./common/components/ErrorBoundary";
import "./index.css";
import { LaunchDarklyProvider } from "./launchDarklyProvider";
import reportWebVitals from "./reportWebVitals";
import store from "./store";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <LaunchDarklyProvider>
    <StyledEngineProvider injectFirst>
      <ModeProvider>
        <ToastProvider position="top">
          <CssBaseline />
          <React.StrictMode>
            <Provider store={store}>
              <ErrorBoundary>
                <App />
              </ErrorBoundary>
            </Provider>
          </React.StrictMode>
        </ToastProvider>
      </ModeProvider>
    </StyledEngineProvider>
  </LaunchDarklyProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
