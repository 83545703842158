import { createApi } from "@reduxjs/toolkit/query/react";
import { HTTP_METHODS } from "../../../common/constants";
import RTKbaseQuery from "../../../common/servicers/RTKbaseQuery";

const tagGateway = ["Gateways"];

export const gatewaysApi = createApi({
  reducerPath: "gatewaysApi",
  baseQuery: RTKbaseQuery,
  tagTypes: tagGateway,
  endpoints: (builder) => ({
    getGateways: builder.query({
      query: ({ organizationId, selectedTenant }) => {
        return {
          url: `/organizations/${organizationId}/tenants/${selectedTenant}/gateways`,
          method: HTTP_METHODS.GET,
        };
      },
      providesTags: tagGateway,
    }),
    createGateways: builder.mutation({
      query: ({ name, selectedTenant, organizationId, siteName, ip, port }) => {
        const payload = {
          fqdn: ip,
          port: port,
          type: "edge",
          siteName,
          name,
        };
        return {
          url: `/organizations/${organizationId}/tenants/${selectedTenant}/gateways`,
          method: HTTP_METHODS.POST,
          body: payload,
        };
      },
      invalidatesTags: tagGateway,
    }),

    deleteGateways: builder.mutation({
      query: ({ selectedTenant, organizationId, clusterId }) => {
        return {
          url: `/organizations/${organizationId}/tenants/${selectedTenant}/gateways/${clusterId}`,
          method: HTTP_METHODS.DELETE,
        };
      },
      invalidatesTags: tagGateway,
    }),
  }),
});

export const {
  useGetGatewaysQuery,
  useCreateGatewaysMutation,
  useDeleteGatewaysMutation,
} = gatewaysApi;
