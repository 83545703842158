import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import {
  DEFAULT_HOLDING_TIME,
  HTTP_METHODS,
  PROGRESS_STATUS,
} from "../common/constants";
import HTTPClient from "../common/servicers/httpClient";
import { getIdentityId, getMessageForCode } from "../common/utils";
import {
  AD_DOMAIN_CONTROLLER_USAGE_TYPES,
  EXISTING_NETWORK_WITH_AD_SERVICES,
  NEW_AD_TYPE,
} from "./onboardingConstants";
import { formRadioOptions } from "./onboardingUtils";

/**************************************
 *********** API ACTIONS ***************
 ***************************************/

export const getTenantPartnerRelationShipStatus = createAsyncThunk(
  "tenantPartnerRelationShipStatus",
  async (
    { useCache = true, organizationId, azureTenantId },
    { getState, requestId, rejectWithValue },
  ) => {
    const {
      currentRequestId,
      loadingTenantPartnerRelationShip,
      tenantRelationShipDetails,
    } = getState().onboarding;
    if (!loadingTenantPartnerRelationShip || requestId !== currentRequestId) {
      return;
    }
    if (useCache && tenantRelationShipDetails[azureTenantId]) {
      return { data: tenantRelationShipDetails[azureTenantId], azureTenantId };
    }
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${organizationId}/tenants/${azureTenantId}/validate`,
        method: HTTP_METHODS.GET,
      }).callAuthorizedAPI();
      return { ...response.data, azureTenantId };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const fetchHypervisorInfo = createAsyncThunk(
  "fetchHypervisorInfo",
  async (
    {
      organizationId,
      tenantId,
      vCenterUrl,
      vCenterPassword,
      vCenterUsername,
      conductorName,
    },
    { getState, requestId, rejectWithValue },
  ) => {
    const { currentRequestId, loading } = getState().onboarding;
    if (!loading || requestId !== currentRequestId) {
      return;
    }
    try {
      var payload = {
        url: vCenterUrl,
        username: vCenterUsername,
        password: vCenterPassword,
      };
      if (conductorName) {
        payload.conductorName = conductorName;
      }
      const response = await new HTTPClient({
        endpoint: `/organizations/${organizationId}/tenants/${tenantId}/hypervisorInfo`,
        method: HTTP_METHODS.POST,
        data: payload,
      }).callAuthorizedAPI();
      return { ...response.data, tenantId };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const fetchResourcePoolFromHypervisor = createAsyncThunk(
  "fetchResourcePoolFromHypervisor",
  async (
    {
      organizationId,
      tenantId,
      vCenterUrl,
      vCenterPassword,
      vCenterUsername,
      type,
      hostOrClusterValue,
      conductorName,
    },
    { getState, requestId, rejectWithValue },
  ) => {
    const { currentRequestId, loadingResourcePools } = getState().onboarding;
    if (!loadingResourcePools || requestId !== currentRequestId) {
      return;
    }
    try {
      const payload = {
        url: vCenterUrl,
        username: vCenterUsername,
        password: vCenterPassword,
      };
      if (conductorName) {
        payload.conductorName = conductorName;
      }
      const response = await new HTTPClient({
        endpoint: `/organizations/${organizationId}/tenants/${tenantId}/hypervisorInfo?${type}=${hostOrClusterValue}`,
        method: HTTP_METHODS.POST,
        data: payload,
      }).callAuthorizedAPI();
      return { ...response.data, tenantId };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const editVCenterCredentials = createAsyncThunk(
  "editVCenterCredentials",
  async (
    {
      organizationId,
      tenantId,
      siteName,
      vCenterUrl,
      vCenterUsername,
      vCenterPassword,
    },
    { getState, requestId, rejectWithValue },
  ) => {
    const { loadingEditVCenterCredentials, currentRequestId } =
      getState().onboarding;
    if (!loadingEditVCenterCredentials || requestId !== currentRequestId) {
      return;
    }
    try {
      const payload = {
        endpoint: `/organizations/${organizationId}/tenants/${tenantId}/sites/${siteName}/hypervisorCredentials`,
        method: HTTP_METHODS.PUT,
        data: {
          url: vCenterUrl,
          username: vCenterUsername,
          password: vCenterPassword,
        },
      };
      const response = await new HTTPClient(payload).callAuthorizedAPI();
      return { ...response.data, tenantId, siteName };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const validateVCenterCredentials = createAsyncThunk(
  "validateVCenterCredentials",
  async (
    {
      organizationId,
      tenantId,
      vCenterUrl,
      vCenterUsername,
      vCenterPassword,
      conductorName,
    },
    { getState, requestId, rejectWithValue },
  ) => {
    const { currentRequestId, loadingVcenterValidation } =
      getState().onboarding;
    if (!loadingVcenterValidation || requestId !== currentRequestId) {
      return;
    }
    try {
      var payload = {
        url: vCenterUrl,
        username: vCenterUsername,
        password: vCenterPassword,
      };
      if (conductorName) {
        payload.conductorName = conductorName;
      }
      const response = await new HTTPClient({
        endpoint: `/organizations/${organizationId}/tenants/${tenantId}/hypervisorCredentials?credentialsType=vCenter`,
        method: HTTP_METHODS.POST,
        data: payload,
      }).callAuthorizedAPI();
      return { ...response.data, tenantId };
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const validateADCredentials = createAsyncThunk(
  "validateADCredentials",
  async (
    {
      organizationId,
      tenantId,
      domainUrl,
      domainUsername,
      domainPassword,
      conductorName,
    },
    { getState, requestId, rejectWithValue },
  ) => {
    const { currentRequestId, loadingADValidation } = getState().onboarding;
    if (!loadingADValidation || requestId !== currentRequestId) {
      return;
    }
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${organizationId}/tenants/${tenantId}/hypervisorCredentials?credentialsType=activeDirectory`,
        method: HTTP_METHODS.POST,
        data: {
          url: domainUrl,
          username: domainUsername,
          password: domainPassword,
          conductorName: conductorName,
        },
      }).callAuthorizedAPI();
      return { ...response.data, tenantId };
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const saveADCredentials = createAsyncThunk(
  "saveADCredentials",
  async (
    { organizationId, tenantId, domainUsername, domainPassword },
    { getState, requestId, rejectWithValue },
  ) => {
    const { currentRequestId, loadingADsave } = getState().onboarding;
    if (!loadingADsave || requestId !== currentRequestId) {
      return;
    }
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${organizationId}/tenants/${tenantId}/adCredentials`,
        method: HTTP_METHODS.POST,
        data: { username: domainUsername, password: domainPassword },
      }).callAuthorizedAPI();
      return { ...response.data, tenantId };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const finalizeOnboarding = createAsyncThunk(
  "finalizeOnboarding",
  async (
    { organizationId, tenantId, data },
    { getState, requestId, rejectWithValue },
  ) => {
    const { currentRequestId, finallizingOnboarding } = getState().onboarding;
    if (!finallizingOnboarding || requestId !== currentRequestId) {
      return;
    }
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${organizationId}/tenants/${tenantId}/finalizeOnboarding`,
        method: HTTP_METHODS.POST,
        data: data,
      }).callAuthorizedAPI();
      return { ...response.data, tenantId };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const createSite = createAsyncThunk(
  "createSite",
  async (
    { organizationId, tenantId, site },
    { getState, requestId, rejectWithValue },
  ) => {
    const { currentRequestId, loadingCreateSite } = getState().onboarding;
    if (!loadingCreateSite || requestId !== currentRequestId) {
      return;
    }
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${organizationId}/tenants/${tenantId}/sites/`,
        method: HTTP_METHODS.POST,
        data: site,
      }).callAuthorizedAPI();
      return { ...response.data, tenantId };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getTenantDeploymentStatus = createAsyncThunk(
  "tenantDeploymentStatus",
  async (
    { organizationId, tenantId, tenantType },
    { getState, requestId, rejectWithValue },
  ) => {
    const { currentRequestId, loadingDeploymentStatus } = getState().onboarding;
    if (!loadingDeploymentStatus || requestId !== currentRequestId) {
      return;
    }
    try {
      var endpoint = null;
      if (tenantId) {
        endpoint = `/organizations/${organizationId}/deploymentStatus?tenantType=${tenantType}&tenantId=${tenantId}`;
      } else {
        endpoint = `/organizations/${organizationId}/deploymentStatus?tenantType=${tenantType}`;
      }
      const response = await new HTTPClient({
        endpoint: endpoint,
        method: HTTP_METHODS.GET,
      }).callAuthorizedAPI();
      return { ...response.data, tenantId, tenantType };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const createEntraApplication = createAsyncThunk(
  "createEntraApplication",
  async (
    { organizationId, azureTenantId },
    { getState, requestId, rejectWithValue },
  ) => {
    const { currentRequestId, creatingEntraApplication } =
      getState().onboarding;
    if (!creatingEntraApplication || requestId !== currentRequestId) {
      return;
    }
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${organizationId}/tenants/${azureTenantId}/servicePrincipal`,
        method: HTTP_METHODS.GET,
      }).callAuthorizedAPI();
      return { ...response.data, azureTenantId };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const createTenant = createAsyncThunk(
  "createTenant",
  async (
    { payload, selectedOrganization },
    { getState, requestId, rejectWithValue },
  ) => {
    const { currentRequestId, loading } = getState().onboarding;
    if (!loading || requestId !== currentRequestId) {
      return;
    }
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${selectedOrganization}/tenants`,
        method: HTTP_METHODS.POST,
        data: payload,
      }).callAuthorizedAPI();
      return { ...response.data, tenantId: payload.tenantId };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const createTenantAme = createAsyncThunk(
  "createTenantAme",
  async (
    { payload, selectedOrganization },
    { getState, requestId, rejectWithValue },
  ) => {
    const { currentRequestId, loading } = getState().onboarding;
    if (!loading || requestId !== currentRequestId) {
      return;
    }
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${selectedOrganization}/tenants`,
        method: HTTP_METHODS.POST,
        data: payload,
      }).callAuthorizedAPI();
      return { ...response.data };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getTenant = createAsyncThunk(
  "getTenant",
  async (
    { useCache = true, selectedOrganization },
    { getState, requestId, rejectWithValue },
  ) => {
    const { currentRequestId, loadingTenant, tenantId, tenant } =
      getState().onboarding;
    if (!loadingTenant || requestId !== currentRequestId) {
      return;
    }
    if (useCache && tenant[tenantId]) {
      return { data: tenant[tenantId], tenantId };
    }
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${selectedOrganization}/tenants/${tenantId}`,
        method: HTTP_METHODS.GET,
      }).callAuthorizedAPI();
      return { ...response.data, tenantId };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getExistingNetworks = createAsyncThunk(
  "getExistingNetworks",
  async (
    { useCache = true, selectedOrganization },
    { getState, requestId, rejectWithValue },
  ) => {
    const { currentRequestId, loadingNetwork, tenantId, existingNetworks } =
      getState().onboarding;
    if (!loadingNetwork || requestId !== currentRequestId || !tenantId) {
      return;
    }
    if (useCache && existingNetworks[tenantId]) {
      return { data: existingNetworks[tenantId], tenantId };
    }
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${selectedOrganization}/tenants/${tenantId}/deployments/existingNetworks`,
        method: HTTP_METHODS.GET,
      }).callAuthorizedAPI();
      return { ...response.data, tenantId };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);
export const createDeployment = createAsyncThunk(
  "createDeployment",
  async (
    { payload, selectedOrganization },
    { getState, requestId, rejectWithValue },
  ) => {
    const { currentRequestId, loading, tenantId } = getState().onboarding;
    if (!loading || requestId !== currentRequestId || !tenantId) {
      return;
    }
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${selectedOrganization}/tenants/${tenantId}/deployments`,
        method: HTTP_METHODS.PUT,
        data: payload,
      }).callAuthorizedAPI();
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getDeployment = createAsyncThunk(
  "getDeployment",
  async (
    { selectedOrganization },
    { getState, requestId, rejectWithValue },
  ) => {
    const { getDeploymentRequestId, loadingDeployments, tenantId } =
      getState().onboarding;
    if (
      !loadingDeployments ||
      requestId !== getDeploymentRequestId ||
      !tenantId
    ) {
      return;
    }
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${selectedOrganization}/tenants/${tenantId}/deployments`,
        method: HTTP_METHODS.GET,
      }).callAuthorizedAPI();
      if (response.data?.data?.type) {
        return response.data;
      }
      throw new Error("deployment data doesn't exist");
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getDeploymentTypes = createAsyncThunk(
  "getDeploymentTypes",
  async ({ useCache = true }, { getState, requestId, rejectWithValue }) => {
    const {
      currentDeploymentTypesRequestId,
      loadingDeploymentTypes,
      deploymentTypes,
    } = getState().onboarding;
    if (
      !loadingDeploymentTypes ||
      requestId !== currentDeploymentTypesRequestId
    ) {
      return;
    }
    if (useCache && deploymentTypes && deploymentTypes.length > 0) {
      return { data: deploymentTypes, useCache };
    }
    try {
      const response = await new HTTPClient({
        endpoint: "/deployments/types",
        method: HTTP_METHODS.GET,
      }).callAuthorizedAPI();
      return { ...response.data };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const deploy = createAsyncThunk(
  "deploy",
  async (
    dataToBeUserdInAPIPayload,
    { getState, requestId, rejectWithValue },
  ) => {
    const { currentRequestId, loading, tenantId } = getState().onboarding;
    if (!loading || requestId !== currentRequestId) {
      return;
    }
    const selectedIdentity = getState().identities.selectedIdentity;
    const organizationId = getIdentityId(selectedIdentity);
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${organizationId}/tenants/${tenantId}/deployments/deploy`,
        method: HTTP_METHODS.PUT,
      }).callAuthorizedAPI();
      return { ...response.data };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const peerVirtualNetwork = createAsyncThunk(
  "peerVirtualNetwork",
  async (
    { forwardTrafficSpotPcVnet },
    { getState, requestId, rejectWithValue },
  ) => {
    const { currentRequestId, loading, tenantId } = getState().onboarding;
    if (!loading || requestId !== currentRequestId) {
      return;
    }
    const selectedIdentity = getState().identities.selectedIdentity;
    const organizationId = getIdentityId(selectedIdentity);
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${organizationId}/tenants/${tenantId}/deployments/peerVirtualNetworks?allowForwardTrafficSpotPcVnet=${forwardTrafficSpotPcVnet}`,
        method: HTTP_METHODS.PUT,
      }).callAuthorizedAPI();
      return { ...response.data };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const discoverLogs = createAsyncThunk(
  "discoverLogs",
  async (
    dataToBeUserdInAPIPayload,
    { getState, requestId, rejectWithValue },
  ) => {
    const { currentRequestId, loading, tenantId } = getState().onboarding;
    if (!loading || requestId !== currentRequestId) {
      return;
    }
    const selectedIdentity = getState().identities.selectedIdentity;
    const organizationId = getIdentityId(selectedIdentity);
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${organizationId}/tenants/${tenantId}/deployments/discovery`,
        method: HTTP_METHODS.PUT,
      }).callAuthorizedAPI();
      return { ...response.data };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const registerAzureTenant = createAsyncThunk(
  "registerAzureTenant",
  async (
    { tenantId, azureTenantId, organizationId },
    { getState, requestId, rejectWithValue },
  ) => {
    const { registeringTenantRequestId, registeringTenant } =
      getState().onboarding;

    if (!registeringTenant || registeringTenantRequestId !== requestId) {
      return;
    }
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${organizationId}/tenants/${tenantId}/registerAzureTenant/${azureTenantId}`,
        method: HTTP_METHODS.PUT,
      }).callAuthorizedAPI();
      return { ...response.data, azureTenantId, tenantId };
    } catch (error) {
      return rejectWithValue(error.response?.data?.message);
    }
  },
);

const initialState = {
  tenantId: "",
  azureTenantId: "",
  azureRegion: null,
  azureAccountLinkingStatus: null,
  activeDirectoryDomainController: null,
  isAdConnectedToAzure: false,
  domainUsername: "",
  domainPassword: "",
  vCenterUrl: "",
  vCenterUsername: "",
  vCenterPassword: "",
  azureADSyncStatus: false,
  networkScope: "",
  networkAddress: "",
  validateNetworkStatus: false,
  authorizedNetworkStatus: false,
  network: "",
  spotNetworkData: null,
  loadingTenantPartnerRelationShip: false,
  tenantRelationShipDetails: {},
  tenantRelationShipUri: null,
  tenantRelationShipUriError: null,
  loadingTenantPartnerRelationUri: null,
  activeStep: 0,
  deploymentChanged: false,
  deployment: null,
  loading: false,
  deploymentTypes: [],
  loadingDeploymentTypes: false,
  currentDeploymentTypesRequestId: null,
  domain: null,
  existingNetworks: {},
  loadingDeployments: false,
  getDeploymentRequestId: null,
  error: null,
  tenant: {},
  loadingData: false,
  deploymentLogs: [],
  loadingNetwork: false,
  loadingTenant: false,
  azureADDomainServices: false,
  spotPcVnetAddress: null,
  domainNetBIOS: "",
  discoveringLogs: false,
  discoveryStatus: false,
  registeringTenant: false,
  registeringTenantRequestId: null,
  validateError: null,
  isAzureAdDirectJoin: false,
  healthCheckSkipped: false,
  pcoipRegistrationCode: null,
  poolName: null,
  deviceImage: null,
  preferredGeography: null,
  createSiteError: false,
  resourcePool: null,
  dataStore: null,
  displayName: null,
  loadingDeploymentStatus: true,
  deploymentStatus: [],
  validADCredentials: null,
  validADsave: false,
  validVCenterCredentials: null,
  loadingVcenterValidation: false,
  loadingADValidation: false,
  loadingADsave: false,
  loadingResourcePools: false,
  errorVCenterValidation: "",
  errorADCredentialsValidation: "",
  validateVCenterCredentialsError: null,
  loadingEditVCenterCredentials: null,
  editVCenterCredentialsError: null,
  editVCenterCredentialsSuccessResponse: null,
  displayHeaderName: "",
  persistent: "",
  targetUsers: [],
  agentType: null,
  deploymentStatusProgress: 0,
  entraGroups: [],
  holdingTime: DEFAULT_HOLDING_TIME,
};

const onboardingSlice = createSlice({
  name: "onboarding",
  initialState,
  reducers: {
    updateTenantOnboardingStatus: (state, action) => {
      if (action.payload !== undefined && action.payload.data) {
        const data = JSON.parse(action.payload.data);
        if (state.tenant[data.tenantId] !== undefined) {
          let stepIndex = state.tenant[
            data.tenantId
          ].deploymentStatus.findIndex((x) => x.step === data.step);

          if (state.tenant[data.tenantId].deploymentStatus[stepIndex]) {
            state.tenant[data.tenantId].deploymentStatus[stepIndex].status =
              data.status;
          }
        }
      }
    },
    // eslint-disable-next-line max-statements
    resetData: (state) => {
      state.deploymentStatusProgress = 0;
      state.tenantRelationShipUri = null;
      state.tenantRelationShipDetails = {};
      state.tenantRelationShipUriError = null;
      state.tenantId = "";
      state.azureTenantId = "";
      state.azureRegion = null;
      state.preferredGeography = null;
      state.azureAccountLinkingStatus = null;
      state.activeDirectoryDomainController = null;
      state.isAdConnectedToAzure = false;
      state.domainUsername = "";
      state.domainPassword = "";
      state.azureADSyncStatus = false;
      state.networkScope = "";
      state.networkAddress = "";
      state.validateNetworkStatus = false;
      state.authorizedNetworkStatus = false;
      state.azureADDomainServices = false;
      state.network = "";
      state.spotNetworkData = null;
      state.activeStep = 0;
      state.deployment = null;
      state.deploymentChanged = false;
      state.domain = null;
      state.error = null;
      state.spotPcVnetAddress = null;
      state.domainNetBIOS = "";
      state.discoveringLogs = false;
      state.validateError = null;
      state.healthCheckSkipped = false;
      state.tenant = {};
      state.pcoipRegistrationCode = null;
      state.cpin = null;
      state.poolName = null;
      state.deviceImage = null;
      state.createSiteError = false;
      state.vCenterUrl = null;
      state.vCenterUsername = null;
      state.vCenterPassword = null;
      state.resourcePool = null;
      state.dataStore = null;
      state.displayName = null;
      state.loadingDeploymentStatus = true;
      state.registeringTenant = false;
      state.deploymentStatus = [];
      state.loadingADValidation = false;
      state.loadingADsave = false;
      state.loadingVcenterValidation = false;
      state.validADCredentials = null;
      state.validADsave = false;
      state.validVCenterCredentials = null;
      state.loadingResourcePools = false;
      state.persistent = "";
      state.targetUsers = [];
      state.agentType = null;
      state.entraGroups = [];
      state.holdingTime = DEFAULT_HOLDING_TIME;
    },
    resetError: (state) => {
      state.error = null;
    },
    resetVCenterError: (state) => {
      state.editVCenterCredentialsError = null;
      state.validateVCenterCredentialsError = null;
      state.loadingVcenterValidation = false;
    },
    resetAdCredentials: (state) => {
      state.validADCredentials = null;
      state.loadingADValidation = false;
    },
    setDeploymentStatusProgress: (state, action) => {
      state.deploymentStatusProgress = action.payload;
    },
    setPoolName: (state, action) => {
      state.poolName = action.payload;
    },
    setTenantId: (state, action) => {
      state.tenantId = action.payload;
    },
    setTenant: (state, action) => {
      state.tenant = action.payload;
    },
    setAzureTenantId: (state, action) => {
      state.azureTenantId = action.payload;
    },
    setAzureAdDirectJoin: (state, action) => {
      state.isAzureAdDirectJoin = action.payload;
    },
    setAzureRegion: (state, action) => {
      state.azureRegion = action.payload;
    },
    setDeviceImage: (state, action) => {
      state.deviceImage = action.payload;
    },
    setPreferredGeography: (state, action) => {
      state.preferredGeography = action.payload;
    },
    setAzureAccountLinkingStatus: (state, action) => {
      state.azureAccountLinkingStatus = action.payload;
    },
    setActiveDirectoryDomainController: (state, action) => {
      state.activeDirectoryDomainController = action.payload;
    },
    setIsAdConnectedToAzure: (state, action) => {
      state.isAdConnectedToAzure = action.payload;
    },
    setResourcePool: (state, action) => {
      state.resourcePool = action.payload;
    },
    setDataStore: (state, action) => {
      state.dataStore = action.payload;
    },
    setDomainUsername: (state, action) => {
      state.domainUsername = action.payload;
    },
    setDomainPassword: (state, action) => {
      state.domainPassword = action.payload;
    },
    setVCenterUrl: (state, action) => {
      state.vCenterUrl = action.payload;
    },
    setVCenterPassword: (state, action) => {
      state.vCenterPassword = action.payload;
    },
    setVCenterUsername: (state, action) => {
      state.vCenterUsername = action.payload;
    },
    setAzureADSyncStatus: (state, action) => {
      state.azureADSyncStatus = action.payload;
    },
    setAuthorizedNetworkStatus: (state, action) => {
      state.authorizedNetworkStatus = action.payload;
    },
    setNetwork: (state, action) => {
      state.network = action.payload;
    },
    setActiveStep: (state, action) => {
      state.activeStep = action.payload;
    },
    setDeployment: (state, action) => {
      state.deployment = action.payload;
    },
    setDeploymentChanged: (state, action) => {
      state.deploymentChanged = action.payload;
    },
    setDeploymentTypes: (state, action) => {
      state.deploymentTypes = action.payload;
    },
    setDomain: (state, action) => {
      state.domain = action.payload;
    },
    setAzureADDomainServices: (state, action) => {
      state.azureADDomainServices = action.payload;
    },
    setSpotPcVnetAddress: (state, action) => {
      state.spotPcVnetAddress = action.payload;
    },
    setDomainNetBIOS: (state, action) => {
      state.domainNetBIOS = action.payload;
    },
    setDiscoveringLogs: (state, action) => {
      state.discoveringLogs = action.payload;
    },
    setHealthCheckSkipped: (state, action) => {
      state.healthCheckSkipped = action.payload;
    },
    setPcoipRegistrationCode: (state, action) => {
      state.pcoipRegistrationCode = action.payload;
    },
    setCpin: (state, action) => {
      state.cpin = action.payload;
    },
    setDisplayName: (state, action) => {
      state.displayName = action.payload;
    },
    setValidADsave: (state, action) => {
      state.validADsave = action.payload;
    },
    setPersistent: (state, action) => {
      state.persistent = action.payload;
    },
    setTargetUsers: (state, action) => {
      state.targetUsers = action.payload;
    },
    setAgentType: (state, action) => {
      state.agentType = action.payload;
    },
    setEntraGroups: (state, action) => {
      state.entraGroups = action.payload;
    },
    setHoldingTime: (state, action) => {
      state.holdingTime = action.payload;
    },
  },
  extraReducers: {
    [getTenantPartnerRelationShipStatus.pending]: (state, action) => {
      state.loadingTenantPartnerRelationShip = true;
      state.currentRequestId = action.meta.requestId;
    },
    [getTenantPartnerRelationShipStatus.fulfilled]: (state, action) => {
      state.currentRequestId = null;

      // state.tenantRelationShipUri = action.payload.data.partnerCustomerRelationshipURI;
      // state.isAdConnectedToAzure =
      //   action.payload && action.payload.data && action.payload.data.customer?.id ? true : false;

      if (action.payload.azureTenantId) {
        _.set(
          state.tenantRelationShipDetails,
          `${action.payload.azureTenantId}`,
          action.payload.data,
        );
        _.set(state.tenant, `${action.payload.data.id}`, {
          ...action.payload.data,
        });
      }
      state.displayName = action.payload.data.displayName;
      state.loadingTenantPartnerRelationShip = false;
      state.azureAccountLinkingStatus = true;
    },
    [getTenantPartnerRelationShipStatus.rejected]: (state, action) => {
      state.currentRequestId = null;
      if (action?.payload?.code !== 404) {
        state.error = action.payload;
      }
      state.loadingTenantPartnerRelationShip = false;
      state.azureAccountLinkingStatus = false;
      state.isAdConnectedToAzure = false;
    },
    [createTenant.pending]: (state, action) => {
      state.loading = "Creating Tenant";
      state.error = null;
      state.currentRequestId = action.meta.requestId;
      state.error = null;
      state.activeStep = null;
    },
    [createTenant.fulfilled]: (state, action) => {
      state.currentRequestId = null;
      state.loading = false;
      if (action.payload.tenantId) {
        _.set(state.tenant, `${action.payload.tenantId}`, action.payload);
        state.spotPcVnetAddress = action.payload?.data?.spotPcVnetAddress;
      }
    },
    [createTenant.rejected]: (state, action) => {
      state.currentRequestId = null;
      if (action.payload?.code !== 409) {
        if (action.payload?.code === 403) {
          state.authorizedNetworkStatus = false;
          state.activeStep = 1;
        }
        state.error = action.payload;
      }
      state.loading = false;
    },

    [createTenantAme.pending]: (state, action) => {
      state.loading = "Creating AME Tenant";
      state.error = null;
      state.currentRequestId = action.meta.requestId;
      state.error = null;
      state.activeStep = null;
    },
    [createTenantAme.fulfilled]: (state, action) => {
      state.currentRequestId = null;
      state.loading = false;

      if (action.payload.data) {
        var id = action.payload.data.id.split("/");
        _.set(state.tenant, `${id[2]}`, {
          ...action.payload.data,
          deploymentStatus: state.deploymentStatus,
        });
        state.tenantId = id[2];
      }
    },
    [createTenantAme.rejected]: (state, action) => {
      state.currentRequestId = null;
      state.error = action.payload;
      state.activeStep = 0;
      state.loading = false;
    },

    [createDeployment.pending]: (state, action) => {
      state.loading = "Saving Deployment";
      state.currentRequestId = action.meta.requestId;
      state.error = null;
    },
    [createDeployment.fulfilled]: (state, action) => {
      state.currentRequestId = null;
      state.loading = false;
      state.deploymentChanged = false;
      state.deployment = action.payload.data;
    },
    [createDeployment.rejected]: (state, action) => {
      state.authorizedNetworkStatus = false;
      state.currentRequestId = null;
      state.error = action.payload;
      state.deploymentChanged = false;
      state.loading = false;
      state.deployment = false;
    },
    [getDeployment.pending]: (state, action) => {
      state.loadingDeployments = true;
      state.getDeploymentRequestId = action.meta.requestId;
    },
    [getDeployment.fulfilled]: (state, action) => {
      state.getDeploymentRequestId = null;
      state.loadingDeployments = false;
      state.deployment = action.payload?.data;
      if (action.payload?.data) {
        state.activeDirectoryDomainController = action.payload?.data.type;
        if (
          action.payload?.data.type ===
          AD_DOMAIN_CONTROLLER_USAGE_TYPES["EXISTING"]
        ) {
          if (
            action.payload?.data?.properties?.existingActiveDirectoryProperties
              ?.azureADDomainServices
          ) {
            state.activeDirectoryDomainController =
              AD_DOMAIN_CONTROLLER_USAGE_TYPES.EXISTING_AZURE_AD;
          }
          state.domainUsername =
            action.payload?.data?.properties.existingActiveDirectoryProperties.domainUsername;
          state.domainPassword =
            action.payload?.data?.properties.existingActiveDirectoryProperties.domainPassword;
          state.domain =
            action.payload?.data?.properties.existingActiveDirectoryProperties.domain;
          state.isAdConnectedToAzure =
            state.domainPassword && state.domainUsername ? "true" : "false";
          state.network = {
            resourceId:
              action.payload?.data?.properties.existingActiveDirectoryProperties
                .networkResourceId,
          };
          state.azureADDomainServices =
            action.payload?.data?.properties.existingActiveDirectoryProperties.azureADDomainServices;
          state.domainNetBIOS =
            action.payload?.data?.properties.existingActiveDirectoryProperties.domainNetBIOS;
          state.isAzureAdDirectJoin = false;
        } else if (
          action.payload?.data.type ===
          AD_DOMAIN_CONTROLLER_USAGE_TYPES.AZURE_AD_DIRECT_JOIN
        ) {
          state.activeDirectoryDomainController =
            AD_DOMAIN_CONTROLLER_USAGE_TYPES.AZURE_AD_DIRECT_JOIN;
          state.isAzureAdDirectJoin = true;
        } else {
          state.isAzureAdDirectJoin = false;
          state.domain =
            action.payload?.data?.properties?.newActiveDirectoryProperties?.domain;
        }
      }
    },
    [getDeployment.rejected]: (state, action) => {
      state.getDeploymentRequestId = null;
      if (!(action.payload?.code === 404 || action.payload?.code === 409)) {
        state.error = action.payload;
      }
      state.loadingDeployments = false;
      state.deployment = false;
    },
    [getDeploymentTypes.pending]: (state, action) => {
      state.loadingDeploymentTypes = true;
      state.currentDeploymentTypesRequestId = action.meta.requestId;
    },
    [getDeploymentTypes.fulfilled]: (state, action) => {
      state.currentDeploymentTypesRequestId = null;
      state.loadingDeploymentTypes = false;
      action.payload.data = [
        ...action.payload.data.map((deployment) => {
          return deployment.type === AD_DOMAIN_CONTROLLER_USAGE_TYPES["NEW"]
            ? NEW_AD_TYPE
            : deployment;
        }),
        EXISTING_NETWORK_WITH_AD_SERVICES,
      ];

      if (!action.payload?.useCache) {
        state.deploymentTypes = formRadioOptions(action.payload.data);
      }
    },
    [getDeploymentTypes.rejected]: (state, action) => {
      state.currentDeploymentTypesRequestId = null;
      state.error = action.payload;
      state.loadingDeploymentTypes = false;
    },
    [getTenant.pending]: (state, action) => {
      state.loadingTenant = "Loading Tenant";
      state.currentRequestId = action.meta.requestId;
      state.error = null;
    },
    [getTenant.fulfilled]: (state, action) => {
      state.currentRequestId = null;
      state.loadingTenant = false;
      if (action.payload?.tenantId) {
        _.set(state.tenant, `${action.payload.tenantId}`, action.payload.data);
        state.spotPcVnetAddress = action.payload?.data?.spotPcVnetAddress;
        state.discoveryStatus =
          action.payload?.data?.discoveryLogs?.filter(
            (t) => t.status === PROGRESS_STATUS.COMPLETED,
          )?.length === action.payload.data?.discoveryLogs?.length || false;
      }
    },
    [getTenant.rejected]: (state, action) => {
      state.currentRequestId = null;
      if (action.payload?.code !== 404) {
        if (action.payload?.code === 401) {
          state.error = {
            ...action.payload,
            message: "User doesn't have appropriate permision(401).",
          };
        } else {
          state.error = action.payload;
        }
      }
      state.loadingTenant = false;
    },
    [getExistingNetworks.pending]: (state, action) => {
      state.loadingNetwork = true;
      state.currentRequestId = action.meta.requestId;
    },
    [getExistingNetworks.fulfilled]: (state, action) => {
      state.currentRequestId = null;
      state.loadingNetwork = false;
      if (action.payload.tenantId) {
        _.set(
          state.existingNetworks,
          `${action.payload.tenantId}`,
          action.payload.data,
        );
      }
    },
    [getExistingNetworks.rejected]: (state, action) => {
      state.currentRequestId = null;
      state.error = action.payload;
      state.loadingNetwork = false;
    },
    [deploy.pending]: (state, action) => {
      state.loading = "Deploying...";
      state.currentRequestId = action.meta.requestId;
      state.deployment = null;
    },
    [deploy.fulfilled]: (state) => {
      state.currentRequestId = null;
      state.loading = false;
    },
    [deploy.rejected]: (state, action) => {
      state.currentRequestId = null;
      if (
        state.activeDirectoryDomainController ===
          AD_DOMAIN_CONTROLLER_USAGE_TYPES["NEW"] &&
        action.payload?.code === 400
      ) {
        state.error = {
          ...action.payload,
          message: "NewActiveDirectory Deployment is not yet supported.",
        };
      } else {
        state.error = action.payload;
      }
      state.loading = false;
    },
    [peerVirtualNetwork.pending]: (state, action) => {
      state.loading = "Peering Virtual Network";
      state.currentRequestId = action.meta.requestId;
    },
    [peerVirtualNetwork.fulfilled]: (state) => {
      state.currentRequestId = null;
      state.loading = false;
    },
    [peerVirtualNetwork.rejected]: (state, action) => {
      state.currentRequestId = null;
      state.error = action.payload;
      state.loading = false;
    },
    [discoverLogs.pending]: (state, action) => {
      state.loading = "Peering Virtual Network";
      state.currentRequestId = action.meta.requestId;
    },
    [discoverLogs.fulfilled]: (state) => {
      state.currentRequestId = null;
      state.loading = false;
      state.discoveringLogs = true;
    },
    [discoverLogs.rejected]: (state) => {
      state.currentRequestId = null;
      state.loading = false;
    },
    [registerAzureTenant.pending]: (state, action) => {
      state.registeringTenant = true;
      state.registeringTenantRequestId = action.meta.requestId;
      state.validateError = null;
    },
    [registerAzureTenant.fulfilled]: (state, action) => {
      if (action.payload.azureTenantId) {
        _.set(
          state.tenantRelationShipDetails,
          `${action.payload.azureTenantId}`,
          action.payload.data,
        );
        _.set(state.tenant, `${action.payload.data.id}`, {
          ...action.payload.data,
          deploymentStatus:
            state.tenant[action.payload.data.id]?.deploymentStatus ||
            state.deploymentStatus,
        });
        state.displayName = action.payload.data.displayName;
        state.loadingTenantPartnerRelationShip = false;
        state.authorizedNetworkStatus = true;
        state.azureAccountLinkingStatus = true;
        state.registeringTenantRequestId = null;
        state.registeringTenant = false;
        state.validateError = null;
      }
    },
    [registerAzureTenant.rejected]: (state, action) => {
      state.registeringTenantRequestId = null;
      state.authorizedNetworkStatus = false;
      if (action?.error?.message) {
        state.tenantRelationShipUriError = { message: action?.payload };
      }
      _.set(state.tenant, `${action.payload.tenantId}`, {
        deploymentStatus:
          state.tenant[action.payload.tenantId]?.deploymentStatus ||
          state.deploymentStatus,
      });
      state.registeringTenant = false;
    },
    [getTenantDeploymentStatus.pending]: (state, action) => {
      state.loadingDeploymentStatus = true;
      state.currentRequestId = action.meta.requestId;
    },
    [getTenantDeploymentStatus.fulfilled]: (state, action) => {
      state.currentRequestId = null;
      state.loadingTenantDeploymentStatus = false;
      state.deploymentStatus = action.payload.data.steps;
      var tenantId = action.payload.tenantId;
      if (tenantId) {
        _.set(state.tenant, `${tenantId}`, {
          ...state.tenant[tenantId],
          deploymentStatus: action.payload.data.steps,
        });
        state.tenantId = tenantId;
      }

      state.loadingDeploymentStatus = false;
    },
    [getTenantDeploymentStatus.rejected]: (state, action) => {
      state.currentRequestId = null;
      state.error = action.payload;
      state.loadingDeploymentStatus = false;
    },
    [editVCenterCredentials.pending]: (state, action) => {
      state.loadingEditVCenterCredentials = true;
      state.currentRequestId = action.meta.requestId;
      state.editVCenterCredentialsError = null;
    },
    [editVCenterCredentials.fulfilled]: (state, action) => {
      state.loadingEditVCenterCredentials = false;
      state.currentRequestId = null;
      state.editVCenterCredentialsError = false;
      state.editVCenterCredentialsSuccessResponse = action.payload?.data;
    },
    [editVCenterCredentials.rejected]: (state, action) => {
      state.loadingEditVCenterCredentials = false;
      if (!action.payload) {
        state.editVCenterCredentialsError = {
          message: "Something unexpected happened.",
        };
      }
      state.editVCenterCredentialsError = action.payload;
      state.currentRequestId = null;
      state.editVCenterCredentialsSuccessResponse = null;
    },
    [validateVCenterCredentials.pending]: (state, action) => {
      state.loadingVcenterValidation = true;
      state.currentRequestId = action.meta.requestId;
      state.validVCenterCredentials = null;
      state.errorVCenterValidation = "";
    },
    [validateVCenterCredentials.fulfilled]: (state) => {
      state.currentRequestId = null;
      state.loadingVcenterValidation = false;
      state.validateVCenterCredentialsError = false;
      state.validVCenterCredentials = true;
    },
    [validateVCenterCredentials.rejected]: (state, action) => {
      state.currentRequestId = null;
      const codeOrMessage =
        action?.payload?.response?.data?.message ||
        action?.payload?.message ||
        action?.status ||
        "";
      const errorMessage = getMessageForCode(codeOrMessage);
      state.errorVCenterValidation = errorMessage;
      state.loadingVcenterValidation = false;
      state.validVCenterCredentials = false;
    },
    [validateADCredentials.pending]: (state, action) => {
      state.loadingADValidation = true;
      state.currentRequestId = action.meta.requestId;
      state.validADCredentials = null;
      state.errorADCredentialsValidation = "";
    },
    [validateADCredentials.fulfilled]: (state) => {
      state.currentRequestId = null;
      state.loadingADValidation = false;
      state.validADCredentials = true;
    },
    [validateADCredentials.rejected]: (state, action) => {
      state.currentRequestId = null;
      const codeOrMessage =
        action?.payload?.response?.data?.message ||
        action?.payload?.message ||
        action?.status ||
        "";
      const errorMessage = getMessageForCode(codeOrMessage);
      state.errorADCredentialsValidation = errorMessage;
      state.loadingADValidation = false;
      state.validADCredentials = false;
    },
    [saveADCredentials.pending]: (state, action) => {
      state.loadingADsave = true;
      state.currentRequestId = action.meta.requestId;
    },
    [saveADCredentials.fulfilled]: (state) => {
      state.currentRequestId = null;
      state.loadingADsave = false;
      state.validADsave = true;
    },
    [saveADCredentials.rejected]: (state, action) => {
      state.currentRequestId = null;
      state.error = action.payload?.message;
      state.validADsave = false;
      state.loadingADsave = false;
    },
    [fetchHypervisorInfo.pending]: (state, action) => {
      state.loading = true;
      state.currentRequestId = action.meta.requestId;
    },
    [fetchHypervisorInfo.fulfilled]: (state, action) => {
      state.currentRequestId = null;
      state.loading = false;
      state.hypervisorDetails = action.payload.data;
    },
    [fetchHypervisorInfo.rejected]: (state, action) => {
      state.currentRequestId = null;
      state.error = action.payload?.message;
      state.loading = false;
    },
    [fetchResourcePoolFromHypervisor.pending]: (state, action) => {
      state.loadingResourcePools = true;
      state.currentRequestId = action.meta.requestId;
    },
    [fetchResourcePoolFromHypervisor.fulfilled]: (state, action) => {
      state.currentRequestId = null;
      state.loadingResourcePools = false;
      state.resourcePools = action.payload.data?.ResourcePools;
      state.hypervisorDetails.ResourcePools =
        action.payload.data?.ResourcePools;
    },
    [fetchResourcePoolFromHypervisor.rejected]: (state, action) => {
      state.currentRequestId = null;
      state.error = action.payload?.message;
      state.loadingResourcePools = false;
    },
    [createSite.pending]: (state, action) => {
      state.loadingCreateSite = true;
      state.currentRequestId = action.meta.requestId;
      state.createSiteError = false;
    },
    [createSite.fulfilled]: (state, action) => {
      state.currentRequestId = null;
      state.loadingCreateSite = false;
      state.site = action.payload.data;
      state.createSiteError = false;
    },
    [createSite.rejected]: (state, action) => {
      state.currentRequestId = null;
      state.error = action.payload?.message;
      state.createSiteError = true;
      state.loadingCreateSite = false;
    },
    [finalizeOnboarding.pending]: (state, action) => {
      state.finallizingOnboarding = true;
      state.currentRequestId = action.meta.requestId;
    },
    [finalizeOnboarding.fulfilled]: (state) => {
      state.currentRequestId = null;
      state.finallizingOnboarding = false;
    },
    [finalizeOnboarding.rejected]: (state, action) => {
      state.currentRequestId = null;
      state.error = action.payload?.message;
      state.finallizingOnboarding = false;
    },
  },
});

export const {
  setTenantId,
  setAzureRegion,
  setAzureAccountLinkingStatus,
  setActiveDirectoryDomainController,
  setIsAdConnectedToAzure,
  setDomainPassword,
  setDomainUsername,
  setAzureADSyncStatus,
  setAuthorizedNetworkStatus,
  setNetwork,
  setActiveStep,
  setDeploymentChanged,
  setDomain,
  resetData,
  resetError,
  resetVCenterError,
  resetAdCredentials,
  setAzureADDomainServices,
  setSpotPcVnetAddress,
  setDomainNetBIOS,
  setDiscoveringLogs,
  setAzureAdDirectJoin,
  setHealthCheckSkipped,
  updateTenantOnboardingStatus,
  setTenant,
  setAzureTenantId,
  setPcoipRegistrationCode,
  setCpin,
  setPoolName,
  setDeploymentStatusProgress,
  setDeviceImage,
  setPreferredGeography,
  setDisplayName,
  setVCenterPassword,
  setVCenterUrl,
  setVCenterUsername,
  setPersistent,
  setTargetUsers,
  setAgentType,
  setEntraGroups,
  setHoldingTime,
} = onboardingSlice.actions;

export default onboardingSlice.reducer;
