import Back from "@/common/components/Back";
import DashBoardRouteTitle from "@/common/components/DashboardRouteTitle";
import EntityActionDropdown from "@/common/components/EntityActionDropdown";
import ParentRoute from "@/common/components/ParentRoute";
import { ProtectedRouteSuspenseContainer } from "@/common/components/ProtectedRoute";
import {
  SuspenseLoader,
  componentLoader,
} from "@/common/components/SuspenseComponent";
import {
  APPLICATION_CONTEXTS,
  LAYOUTS,
  MODULES_LIST,
  PERMISSIONS_LIST,
  PERMISSIONS_TYPE,
  SUPPORT_ADMIN,
  SUPPORT_ROLES,
} from "@/common/constants";
import { ORG_ROLES } from "@/common/roles";
import { getIdentityId, getTenantId } from "@/common/utils";
import MachinesWrapper from "@/dashboard/components/machines/MachinesWrapper";
import EditTenant from "@/dashboard/components/tenants/EditTenant";
import UserSessionDetailsWrapper from "@/dashboard/components/userSessions/UserSessionDetailsWrapper";
import UserSessionWrapper from "@/dashboard/components/userSessions/UserSessionWrapper";
import { getSiteName } from "@/dashboard/dashboardUtils";
import { getTabTitle, getUserName } from "@/dashboard/selectors";
import {
  logoutUserFromMachine,
  userSessionsSelector,
} from "@/dashboard/slices/userSessionsSlice";
import React from "react";

const RoleDetailsContainer = React.lazy(() =>
  componentLoader(() => import("./components/admins/RoleDetailsContainer")),
);

const AdminDashboard = React.lazy(() =>
  componentLoader(() => import("./components/admins/AdminDashboard")),
);
const MachinesContainer = React.lazy(() =>
  componentLoader(() => import("./components/machines/MachinesContainer")),
);
const UserSessionsContainer = React.lazy(() =>
  componentLoader(
    () => import("./components/userSessions/UserSessionsContainer"),
  ),
);
const UserSessionDetails = React.lazy(() =>
  componentLoader(() => import("./components/userSessions/UserSessionDetails")),
);

const AdminContainer = React.lazy(() =>
  componentLoader(() =>
    componentLoader(() => import("./components/admins/AdminContainer")),
  ),
);

const SupportAdminContainer = React.lazy(() =>
  componentLoader(() => import("./components/admins/SupportAdminContainer")),
);

const UserSessionPerformance = React.lazy(() =>
  componentLoader(
    () => import("./components/userSessions/UserSessionPerformance"),
  ),
);

const LogsContainer = React.lazy(() =>
  componentLoader(() => import("./components/logs/LogsContainer")),
);

const SessionHistoryLogsContainer = React.lazy(() =>
  componentLoader(
    () => import("./components/logs/SessionHistoryLogsContainer"),
  ),
);

const SessionHistoryMachineDetails = React.lazy(() =>
  componentLoader(() => import("./components/logs/MachineDetails")),
);

const CreateNewRoleContainer = React.lazy(() =>
  componentLoader(() =>
    componentLoader(() => import("./components/admins/CreateNewRoleContainer")),
  ),
);

const PoolsTable = React.lazy(() =>
  componentLoader(() =>
    componentLoader(
      () => import("./components/pools/poolsTable/PoolsTable.jsx"),
    ),
  ),
);

const EditPool = React.lazy(() =>
  componentLoader(() =>
    componentLoader(() => import("./components/pools/EditPool.jsx")),
  ),
);

const PcoipSessionPerformance = React.lazy(() =>
  componentLoader(
    () => import("./components/logs/SessionHistoryPerformance.jsx"),
  ),
);

const backComponent = () => <Back label="Back" />;

const DashboardRoutes = [
  {
    path: "/admin",
    component: ParentRoute,
    context: APPLICATION_CONTEXTS.ORGANIZATION,
    authRequired: true,
    nobreadcrumbs: true,
    layoutStyle: LAYOUTS.MENU_SCREEN,
    isTabs: false,
    adminView: true,
    roles: [...SUPPORT_ROLES],
    highlightContent: true,
    routes: [
      {
        path: "/admin",
        exact: true,
        isTab: true,
        adminView: true,
        title: "Overview",
        component: () => (
          <ProtectedRouteSuspenseContainer component={AdminDashboard} />
        ),
        roles: [...SUPPORT_ROLES],
      },
      {
        path: "/admin/manage",
        exact: true,
        redirectTo: "/admin/manage/users",
        adminView: true,
        isTab: true,
        title: "Admins",
        orgRoles: [ORG_ROLES.ORG_ADMIN],
        component: () => (
          <ProtectedRouteSuspenseContainer component={AdminContainer} />
        ),
      },
      {
        path: "/admin/manage/:tab",
        exact: true,
        adminView: true,
        title: getTabTitle,
        orgRoles: [ORG_ROLES.ORG_ADMIN],
        component: () => (
          <ProtectedRouteSuspenseContainer component={AdminContainer} />
        ),
      },
      {
        path: "/admin/support/support-users",
        exact: true,
        adminView: true,
        isTab: true,
        title: "Support Users",
        isPrivate: true,
        component: () =>
          SuspenseLoader(() => (
            <ProtectedRouteSuspenseContainer
              component={SupportAdminContainer}
            />
          )),
        roles: [SUPPORT_ADMIN],
      },
    ],
  },
  {
    path: "/roles",
    title: "Roles",
    component: ParentRoute,
    context: APPLICATION_CONTEXTS.ORGANIZATION,
    authRequired: true,
    routeTitleComponent: () => <DashBoardRouteTitle title="Manage Role" />,
    layoutStyle: LAYOUTS.MENU_SCREEN,
    isTabs: false,
    nobreadcrumbs: true,
    roles: [...SUPPORT_ROLES],
    routes: [
      {
        path: "/roles/create",
        title: "Create",
        exact: true,
        adminView: true,
        backComponent,
        component: () => (
          <ProtectedRouteSuspenseContainer component={CreateNewRoleContainer} />
        ),
        roles: [...SUPPORT_ROLES],
      },
      {
        path: "/roles/:roleId",
        exact: true,
        adminView: true,
        isTab: false,
        title: "Roles",
        backComponent,
        component: () => (
          <ProtectedRouteSuspenseContainer component={RoleDetailsContainer} />
        ),
      },
    ],
  },
  {
    path: "/tenants",
    component: ParentRoute,
    authRequired: true,
    layoutStyle: LAYOUTS.MENU_SCREEN,
    title: "Overview",
    roles: [...SUPPORT_ROLES],
    highlightContent: true,
    routes: [
      {
        path: "/tenants/edit/:tenantId",
        exact: true,
        component: () => (
          <ProtectedRouteSuspenseContainer component={EditTenant} />
        ),
        roles: [...SUPPORT_ROLES],
        permissionSet: [
          PERMISSIONS_LIST[
            `${MODULES_LIST.DASHBOARD}.${MODULES_LIST.OVERVIEW}`
          ][PERMISSIONS_TYPE.VIEW],
        ],
      },
    ],
  },
  {
    path: "/dashboard/logs/sessionHistory/performance",
    component: ParentRoute,
    authRequired: true,
    nobreadcrumbs: false,
    layoutStyle: LAYOUTS.MENU_SCREEN,
    highlightContent: true,
    title: "Performance",
    roles: [...SUPPORT_ROLES],
    isAutoHeight: true,
    routes: [
      {
        path: "/dashboard/logs/sessionHistory/performance/:pcoipSessionId",
        exact: true,
        renderCTA: true,
        component: () => (
          <ProtectedRouteSuspenseContainer
            component={PcoipSessionPerformance}
          />
        ),
        roles: [...SUPPORT_ROLES],
        permissionSet: [
          PERMISSIONS_LIST[
            `${MODULES_LIST.DASHBOARD}.${MODULES_LIST.OVERVIEW}`
          ][PERMISSIONS_TYPE.VIEW],
        ],
      },
    ],
  },
  {
    path: "/dashboard/logs/sessionHistory/:machineName/:pcoipSessionId",
    component: ParentRoute,
    authRequired: true,
    layoutStyle: LAYOUTS.MENU_SCREEN,
    title: "Machine Details",
    roles: [...SUPPORT_ROLES],
    highlightContent: true,
    routes: [
      {
        path: "/dashboard/logs/sessionHistory/:machineName/:pcoipSessionId",
        title: "Machine Details",
        exact: true,
        component: () => (
          <ProtectedRouteSuspenseContainer
            component={SessionHistoryMachineDetails}
          />
        ),
        roles: [...SUPPORT_ROLES],
        permissionSet: [
          PERMISSIONS_LIST[
            `${MODULES_LIST.LOGS}.${MODULES_LIST.ADMIN_ACTIVITY}`
          ][PERMISSIONS_TYPE.VIEW],
        ],
      },
    ],
  },
  {
    path: "/dashboard/pools/edit",
    component: ParentRoute,
    authRequired: true,
    layoutStyle: LAYOUTS.MENU_SCREEN,
    title: "Pools",
    roles: [...SUPPORT_ROLES],
    highlightContent: true,
    routes: [
      {
        path: "/dashboard/pools/edit/:hypervisorType/:resourcePoolId",
        exact: true,
        renderCTA: true,
        component: () => (
          <ProtectedRouteSuspenseContainer component={EditPool} />
        ),
        roles: [...SUPPORT_ROLES],
        permissionSet: [
          PERMISSIONS_LIST[
            `${MODULES_LIST.DASHBOARD}.${MODULES_LIST.OVERVIEW}`
          ][PERMISSIONS_TYPE.VIEW],
        ],
      },
    ],
  },
  {
    path: "/dashboard",
    component: ParentRoute,
    authRequired: true,
    layoutStyle: LAYOUTS.MENU_SCREEN,
    isTabs: true,
    title: "Overview",
    roles: [...SUPPORT_ROLES],
    highlightContent: true,
    routes: [
      {
        path: "/dashboard/pools",
        title: "Pools",
        isTab: true,
        disabled: false,
        component: ParentRoute,
        renderCTA: true,
        isSubComponent: true,
        permissionSet: [
          PERMISSIONS_LIST[`${MODULES_LIST.RESOURCE_POOLS}`][
            PERMISSIONS_TYPE.VIEW
          ],
        ],
        routes: [
          {
            path: "/dashboard/pools",
            title: "Pools",
            exact: true,
            component: () => (
              <ProtectedRouteSuspenseContainer component={PoolsTable} />
            ),
            permissionSet: [
              PERMISSIONS_LIST[`${MODULES_LIST.RESOURCE_POOLS}`][
                PERMISSIONS_TYPE.VIEW
              ],
            ],
          },
        ],
      },
      {
        path: "/dashboard/userSessions",
        title: "User sessions",
        isTab: true,
        permissionSet: [
          PERMISSIONS_LIST[`${MODULES_LIST.USER_SESSIONS}`][
            PERMISSIONS_TYPE.VIEW
          ],
        ],
        isSubComponent: true,
        component: UserSessionWrapper,
        routes: [
          {
            path: "/dashboard/userSessions",
            title: "User sessions",
            exact: true,
            component: () => (
              <ProtectedRouteSuspenseContainer
                component={UserSessionsContainer}
              />
            ),
            permissionSet: [
              PERMISSIONS_LIST[`${MODULES_LIST.USER_SESSIONS}`][
                PERMISSIONS_TYPE.VIEW
              ],
            ],
          },
          {
            path: "/dashboard/userSessions/:param",
            title: getUserName,
            component: UserSessionDetailsWrapper,
            isSubTabs: true,
            permissionSet: [
              PERMISSIONS_LIST[`${MODULES_LIST.USER_SESSIONS}`][
                PERMISSIONS_TYPE.VIEW
              ],
            ],
            actions: (
              <EntityActionDropdown
                displayText="Quick Actions"
                dontShowBorder={true}
                dropdownData={[
                  {
                    label: "Log Off",
                    value: "logoutUserFromMachine",
                    permissionSet: [
                      PERMISSIONS_LIST[
                        `${MODULES_LIST.USER_SESSIONS}.${MODULES_LIST.QUICK_ACTIONS}.${MODULES_LIST.LOGOFF}`
                      ][PERMISSIONS_TYPE.ACCESS],
                    ],
                    hideOption: (userSessions, params) => {
                      if (userSessions) {
                        return (
                          userSessions.find(
                            (u) =>
                              u.properties.userPrincipalName === params.param,
                          )?.type !== "Spot Group"
                        );
                      }
                      return true;
                    },
                    hideOptionDataSelector: userSessionsSelector,
                    handleClick: async (dispatch, { state, params }) => {
                      const { selectedPartner } = state.partners;
                      const { selectedIdentity } = state.identities;
                      const { param } = params;
                      const { userSessions } = state.dashboard.userSessions;
                      const selectedUserSession = userSessions[
                        getTenantId(selectedPartner)
                      ].find((s) => s.pcoipSessionId === param);
                      const machineName = getSiteName(
                        selectedUserSession.machineName,
                      );
                      await dispatch(
                        logoutUserFromMachine({
                          tenantId: getTenantId(selectedPartner),
                          organizationId: getIdentityId(selectedIdentity),
                          machineName: machineName,
                          userName: selectedUserSession.loggedInUsers,
                        }),
                      );
                    },
                  },
                ]}
              />
            ),
            routes: [
              {
                path: "/dashboard/userSessions/:param",
                exact: true,
                isTab: true,
                title: "Performance",
                component: () => (
                  <ProtectedRouteSuspenseContainer
                    component={UserSessionDetails}
                  />
                ),
                permissionSet: [
                  PERMISSIONS_LIST[
                    `${MODULES_LIST.USER_SESSIONS}.${MODULES_LIST.PERFORMANCE}`
                  ][PERMISSIONS_TYPE.VIEW],
                ],
              },
              {
                path: "/dashboard/userSessions/:param/processes",
                exact: true,
                isTab: true,
                title: "Processes",
                component: () => (
                  <ProtectedRouteSuspenseContainer
                    component={UserSessionPerformance}
                  />
                ),
                permissionSet: [
                  PERMISSIONS_LIST[
                    `${MODULES_LIST.USER_SESSIONS}.${MODULES_LIST.PROCESSES}`
                  ][PERMISSIONS_TYPE.VIEW],
                ],
              },
            ],
          },
        ],
      },
      {
        path: "/dashboard/machines",
        title: "Machines",
        isTab: true,
        component: MachinesWrapper,
        isSubComponent: true,
        permissionSet: [
          PERMISSIONS_LIST[MODULES_LIST.VIRTUAL_MACHINES][
            PERMISSIONS_TYPE.VIEW
          ],
        ],
        routes: [
          {
            path: "/dashboard/machines",
            title: "Machines",
            exact: true,
            component: () => (
              <ProtectedRouteSuspenseContainer component={MachinesContainer} />
            ),
            permissionSet: [
              PERMISSIONS_LIST[MODULES_LIST.VIRTUAL_MACHINES][
                PERMISSIONS_TYPE.VIEW
              ],
            ],
          },
        ],
      },
      {
        path: "/dashboard/logs",
        title: "Logs",
        isTab: true,
        isSubTabs: false,
        component: ParentRoute,
        isSubComponent: true,
        roles: [...SUPPORT_ROLES],
        isAutoHeight: true,
        permissionSet: [
          PERMISSIONS_LIST[`${MODULES_LIST.LOGS}`][PERMISSIONS_TYPE.VIEW],
          PERMISSIONS_LIST[
            `${MODULES_LIST.LOGS}.${MODULES_LIST.ADMIN_ACTIVITY}`
          ][PERMISSIONS_TYPE.VIEW],
          PERMISSIONS_LIST[
            `${MODULES_LIST.LOGS}.${MODULES_LIST.USER_LOGON_ACTIVITY}`
          ][PERMISSIONS_TYPE.VIEW],
        ],
        routes: [
          {
            path: "/dashboard/logs",
            title: "Admin activity",
            exact: true,
            component: () => (
              <ProtectedRouteSuspenseContainer component={LogsContainer} />
            ),
            roles: [...SUPPORT_ROLES],
            permissionSet: [
              PERMISSIONS_LIST[
                `${MODULES_LIST.LOGS}.${MODULES_LIST.ADMIN_ACTIVITY}`
              ][PERMISSIONS_TYPE.VIEW],
            ],
          },
          {
            path: "/dashboard/logs/sessionHistory",
            isTab: true,
            isSubTabs: true,
            title: "Session History",
            exact: true,
            component: () => (
              <ProtectedRouteSuspenseContainer
                component={SessionHistoryLogsContainer}
              />
            ),
            roles: [...SUPPORT_ROLES],
            permissionSet: [
              PERMISSIONS_LIST[
                `${MODULES_LIST.LOGS}.${MODULES_LIST.ADMIN_ACTIVITY}`
              ][PERMISSIONS_TYPE.VIEW],
            ],
          },
        ],
      },
    ],
  },
];

export default DashboardRoutes;
