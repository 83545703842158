import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { HTTP_METHODS, USER_TYPES } from "./constants";
import HTTPClient from "./servicers/httpClient";
import {
  getSelectedOrganizationId,
  needToFetchIdentities,
  saveSelectedOrganizationId,
} from "./utils";

/**************************************
 *********** API ACTIONS ***************
 ***************************************/

export const getIdentities = createAsyncThunk(
  "identities",
  async ({ useCache = true }, { getState, requestId, rejectWithValue }) => {
    const { currentRequestId, loading, identities, identitiesAvailableFor } =
      getState().identities;
    const { userSupportView } = getState().auth;
    if (!loading || requestId !== currentRequestId) {
      return;
    }
    if (
      useCache &&
      identities &&
      !needToFetchIdentities(identitiesAvailableFor, userSupportView)
    ) {
      return { data: identities, userSupportView };
    }
    try {
      const response = await new HTTPClient({
        endpoint: userSupportView
          ? "/internal-support/organizations"
          : "/identities/",
        method: HTTP_METHODS.GET,
      }).callAuthorizedAPI();
      return { data: response.data.data, userSupportView };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const updateOrganization = createAsyncThunk(
  "updateOrganization",
  async (
    { organizationId, payload },
    { getState, requestId, rejectWithValue },
  ) => {
    const { updatingOrgRequestId, updatingOrg } = getState().identities;

    if (!updatingOrg || updatingOrgRequestId !== requestId) {
      return;
    }
    try {
      const formData = new FormData();
      Object.keys(payload).forEach((key) => {
        formData.append(key, payload[key]);
      });

      const response = await new HTTPClient({
        endpoint: `/organizations/identity/${organizationId}`,
        method: HTTP_METHODS.PUT,
        data: formData,
      }).callAuthorizedAPI();
      return { ...response.data, selectedOrg: organizationId };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const initialState = {
  identities: null,
  selectedIdentity: null,
  updatingOrg: false,
  updatingOrgRequestId: null,
  updatingOrgError: null,
  savingOrg: false,
  savingOrgRequestId: null,
  savingOrgError: null,
  requesting: false,
  loading: true,
  identitiesAvailableFor: null,
};

const identities = createSlice({
  name: "identities",
  initialState,
  reducers: {
    resetErrors: (state) => {
      state.updatingOrg = false;
      state.updatingOrgRequestId = null;
      state.updatingOrgError = null;
      state.savingOrg = false;
      state.savingOrgRequestId = null;
      state.savingOrgError = null;
      state.requesting = false;
    },
    setIdentity: (state, action) => {
      if (state.selectedIdentity !== action.payload) {
        state.selectedIdentity = action.payload;
      }
      if (getSelectedOrganizationId() !== action.payload) {
        saveSelectedOrganizationId(action.payload);
      }
    },
  },
  extraReducers: {
    [getIdentities.pending]: (state, action) => {
      state.loading = true;
      state.currentRequestId = action.meta.requestId;
    },
    [getIdentities.fulfilled]: (state, action) => {
      state.loading = false;
      state.currentRequestId = null;
      state.identities = action.payload
        ? action.payload.data
          ? action.payload.data
          : []
        : [];
      state.identitiesAvailableFor = action.payload.userSupportView
        ? USER_TYPES.SUPPORT_USER
        : USER_TYPES.NORMAL_USER;
    },
    [getIdentities.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
      state.currentRequestId = null;
    },
    [updateOrganization.pending]: (state, action) => {
      state.updatingOrg = true;
      state.updatingOrgRequestId = action.meta.requestId;
      state.updatingOrgError = null;
    },
    [updateOrganization.fulfilled]: (state, action) => {
      state.updatingOrgRequestId = null;
      state.updatingOrg = false;
      state.updatingOrgError = false;
      if (action?.payload?.selectedOrg && state.identities) {
        state.identities = state.identities.map((identity) => {
          if (identity.id.split("/")[2] === action.payload.selectedOrg) {
            identity = action.payload.data;
          }
          return identity;
        });
      }
    },
    [updateOrganization.rejected]: (state, action) => {
      state.updatingOrgRequestId = null;
      state.updatingOrgError = action.payload || true;
      state.updatingOrg = false;
    },
  },
});

export const { resetErrors, setIdentity } = identities.actions;

export default identities.reducer;
