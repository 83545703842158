import { useTheme } from "@veneer/theme";

const HPAnywareIcon = () => {
  const { color } = useTheme();
  return (
    <svg
      width="296"
      height="55"
      viewBox="0 0 296 55"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_21001_54253)">
        <path
          d="M28 55C27.7265 55 27.4544 54.995 27.1828 54.9872L32.8028 39.5416H40.5372C41.8947 39.5416 43.3849 38.498 43.8492 37.223L49.9465 20.4753C50.944 17.7333 49.3735 15.49 46.4558 15.49H35.7258L26.7227 40.2293H26.7193L21.6155 54.2534C9.50678 51.3745 0.5 40.4896 0.5 27.5001C0.5 14.885 8.99447 4.25503 20.5753 1.01516L15.3089 15.49H15.3074L6.55243 39.5416L12.3807 39.5421L19.8262 19.1042H24.2064L16.7608 39.5421L22.5868 39.5431L29.5253 20.4753C30.5234 17.7333 28.9527 15.49 26.0378 15.49H21.1386L26.764 0.0289004C27.174 0.0107581 27.5857 0 28 0C43.1879 0 55.5 12.3121 55.5 27.5001C55.5 42.6877 43.1879 55 28 55ZM44.6391 19.0895H40.2601L34.1278 35.9151H38.5068L44.6391 19.0895Z"
          fill={color.accent.base.default}
        />
      </g>
      <path
        d="M110.5 20V40H100.171V21.1111C100.171 15.9259 95.8422 11.7185 90.5 11.7185C85.1596 11.7185 80.8288 15.9241 80.8288 21.1111V40H70.5V20C70.5 14.6957 72.6077 9.60859 76.3595 5.85786C80.1113 2.10714 85.1998 0 90.5056 0C95.8094 0.00294578 100.895 2.11138 104.644 5.86179C108.394 9.6122 110.5 14.6976 110.5 20ZM100.171 39.9889H80.8288L90.5056 55L100.171 39.9889Z"
        fill={color.accent.base.default}
      />
      <g clipPath="url(#clip1_21001_54253)">
        <path
          d="M284.786 40.5249C282.315 40.5249 280.23 39.9998 278.532 38.9497C276.833 37.8687 275.551 36.4016 274.686 34.5484C273.822 32.6643 273.389 30.5177 273.389 28.1086C273.389 25.6068 273.837 23.4293 274.733 21.5762C275.628 19.6921 276.91 18.225 278.578 17.1749C280.246 16.0939 282.238 15.5533 284.554 15.5533C288.014 15.5533 290.701 16.6652 292.616 18.8891C294.562 21.1129 295.581 24.2015 295.674 28.1549C295.674 28.4329 295.535 28.5719 295.257 28.5719H276.678C276.401 28.5719 276.262 28.4329 276.262 28.1549V26.1165C276.262 25.8385 276.401 25.6995 276.678 25.6995H291.087C291.056 24.3714 290.794 23.1668 290.299 22.0858C289.805 21.0048 289.079 20.14 288.122 19.4913C287.164 18.8118 285.991 18.4721 284.601 18.4721C282.284 18.4721 280.539 19.3369 279.366 21.0665C278.223 22.7653 277.651 25.1126 277.651 28.1086C277.651 31.1664 278.238 33.5137 279.412 35.1507C280.616 36.7568 282.423 37.5598 284.832 37.5598C286.593 37.5598 287.998 37.0965 289.048 36.1699C290.099 35.2125 290.794 33.838 291.133 32.0466C291.226 31.7995 291.396 31.676 291.643 31.676H294.84C295.179 31.676 295.318 31.7995 295.257 32.0466C295.01 33.6836 294.438 35.1507 293.542 36.4479C292.678 37.7142 291.504 38.7181 290.021 39.4593C288.57 40.1697 286.825 40.5249 284.786 40.5249Z"
          fill={color.accent.base.default}
        />
        <path
          d="M259.98 39.9689C259.702 39.9689 259.563 39.8299 259.563 39.552L259.702 27.7843L259.563 16.5262C259.563 16.2483 259.702 16.1093 259.98 16.1093H263.408C263.686 16.1093 263.825 16.2483 263.825 16.5262L263.686 20.6496C264.52 19.0744 265.663 17.8852 267.115 17.0822C268.566 16.2791 270.126 15.8776 271.794 15.8776C272.041 15.8776 272.288 15.8931 272.535 15.924C272.782 15.9548 272.999 15.9857 273.184 16.0166C273.431 16.0475 273.555 16.2019 273.555 16.4799V18.889C273.555 19.167 273.416 19.2906 273.138 19.2597C272.952 19.2288 272.751 19.2133 272.535 19.2133C272.319 19.1825 272.072 19.167 271.794 19.167C270.466 19.167 269.215 19.445 268.041 20.0009C266.899 20.526 265.956 21.3599 265.215 22.5027C264.474 23.6146 263.995 25.0354 263.779 26.765L263.872 39.552C263.872 39.8299 263.733 39.9689 263.455 39.9689H259.98Z"
          fill={color.accent.base.default}
        />
        <path
          d="M251.682 39.9689C251.404 39.9689 251.265 39.83 251.265 39.552L251.45 33.8535C251.45 33.6373 251.528 33.4828 251.682 33.3902L252.006 33.1122V22.966L251.728 22.7807C251.512 22.6263 251.404 22.4564 251.404 22.2711L251.265 16.5263C251.265 16.2483 251.404 16.1093 251.682 16.1093H255.11C255.388 16.1093 255.527 16.2483 255.527 16.5263L255.388 27.7843L255.527 39.552C255.527 39.83 255.388 39.9689 255.11 39.9689H251.682ZM243.76 40.4322C241.598 40.4322 239.775 39.8917 238.293 38.8107C236.841 37.6988 235.745 36.2163 235.003 34.3631C234.262 32.5099 233.891 30.4251 233.891 28.1086C233.891 25.7921 234.278 23.6919 235.05 21.8078C235.822 19.9237 236.949 18.4258 238.432 17.3139C239.914 16.202 241.706 15.646 243.806 15.646C246.833 15.646 249.165 16.727 250.802 18.8891C252.439 21.0202 253.257 24.078 253.257 28.0623C253.288 30.4405 252.933 32.5717 252.192 34.4557C251.45 36.3089 250.369 37.776 248.949 38.857C247.559 39.9072 245.829 40.4322 243.76 40.4322ZM244.779 37.4672C246.848 37.4672 248.47 36.695 249.643 35.1507C250.848 33.6064 251.466 31.2899 251.497 28.2013C251.497 25.1435 250.925 22.7807 249.782 21.1129C248.64 19.445 247.003 18.5956 244.872 18.5648C243.358 18.5648 242.107 18.9663 241.119 19.7693C240.131 20.5415 239.389 21.6225 238.895 23.0124C238.432 24.3714 238.2 25.962 238.2 27.7843C238.2 29.6993 238.432 31.3826 238.895 32.8342C239.389 34.2859 240.115 35.4287 241.073 36.2626C242.061 37.0656 243.296 37.4672 244.779 37.4672Z"
          fill={color.accent.base.default}
        />
        <path
          d="M207.967 39.9689C207.689 39.9689 207.519 39.8454 207.457 39.5983L201.342 16.5725C201.249 16.2637 201.373 16.1093 201.712 16.1093H205.094C205.372 16.1093 205.527 16.2328 205.558 16.4799L210.191 36.3089L214.777 16.4799C214.839 16.2328 214.994 16.1093 215.241 16.1093H219.549C219.796 16.1093 219.951 16.2328 220.013 16.4799L224.784 36.0772L229.232 16.4799C229.294 16.2328 229.448 16.1093 229.695 16.1093H232.753C233.093 16.1093 233.216 16.2637 233.124 16.5725L227.286 39.5983C227.224 39.8454 227.07 39.9689 226.823 39.9689H222.653C222.406 39.9689 222.236 39.8454 222.144 39.5983L217.094 19.6766L212.554 39.5983C212.523 39.8454 212.368 39.9689 212.09 39.9689H207.967Z"
          fill={color.accent.base.default}
        />
        <path
          d="M185.85 47.8449C185.696 47.8449 185.588 47.7986 185.526 47.7059C185.464 47.6133 185.449 47.5052 185.48 47.3816L188.074 40.0616L178.716 16.5725C178.654 16.449 178.654 16.3409 178.716 16.2482C178.777 16.1556 178.886 16.1093 179.04 16.1093H182.932C183.179 16.1093 183.333 16.2174 183.395 16.4336L190.159 34.502L195.904 16.4799C195.966 16.2328 196.12 16.1093 196.367 16.1093H199.842C199.996 16.1093 200.104 16.1556 200.166 16.2482C200.228 16.3409 200.243 16.449 200.213 16.5725L189.464 47.5206C189.402 47.7368 189.248 47.8449 189.001 47.8449H185.85Z"
          fill={color.accent.base.default}
        />
        <path
          d="M157.866 39.9689C157.588 39.9689 157.449 39.83 157.449 39.552L157.588 29.1279L157.496 16.5263C157.496 16.2483 157.635 16.1093 157.913 16.1093H161.202C161.48 16.1093 161.619 16.2483 161.619 16.5263L161.526 20.2789C162.021 19.445 162.638 18.6729 163.38 17.9625C164.152 17.2521 165.032 16.6807 166.02 16.2483C167.04 15.785 168.182 15.5533 169.449 15.5533C171.92 15.5533 173.865 16.3564 175.286 17.9625C176.738 19.5377 177.464 21.9005 177.464 25.0509V29.7765L177.556 39.552C177.556 39.83 177.417 39.9689 177.139 39.9689H173.665C173.387 39.9689 173.248 39.83 173.248 39.552L173.34 29.7765V25.1899C173.34 22.9661 172.877 21.3445 171.95 20.3253C171.024 19.2751 169.804 18.7501 168.29 18.7501C167.271 18.7501 166.314 19.028 165.418 19.584C164.553 20.14 163.797 20.9584 163.148 22.0395C162.499 23.0896 162.021 24.3868 161.712 25.9311V29.7765L161.804 39.552C161.804 39.83 161.665 39.9689 161.387 39.9689H157.866Z"
          fill={color.accent.base.default}
        />
        <path
          d="M133.82 30.1471C133.542 30.1471 133.403 30.0081 133.403 29.7301V27.1357C133.403 26.8268 133.542 26.6724 133.82 26.6724H148.414C148.692 26.6724 148.831 26.8268 148.831 27.1357V29.7301C148.831 30.0081 148.692 30.1471 148.414 30.1471H133.82ZM127.056 39.9689C126.933 39.9689 126.825 39.9226 126.732 39.8299C126.67 39.7373 126.67 39.6292 126.732 39.5056L138.036 7.86264C138.129 7.64643 138.299 7.53833 138.546 7.53833H143.503C143.75 7.53833 143.905 7.66188 143.966 7.90897L155.178 39.5056C155.24 39.6292 155.24 39.7373 155.178 39.8299C155.116 39.9226 155.008 39.9689 154.854 39.9689H151.194C150.978 39.9689 150.823 39.8454 150.731 39.5983L140.816 10.5961L131.041 39.5983C130.979 39.8454 130.824 39.9689 130.577 39.9689H127.056Z"
          fill={color.accent.base.default}
        />
      </g>
      <defs>
        <clipPath id="clip0_21001_54253">
          <rect
            width="55"
            height="55"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
        <clipPath id="clip1_21001_54253">
          <rect
            width="169"
            height="55"
            fill="white"
            transform="translate(126.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default HPAnywareIcon;
