import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { HTTP_METHODS } from "../common/constants";
import HTTPClient from "../common/servicers/httpClient";

export const getCiObjectInfo = createAsyncThunk(
  "getCiObjectInfo",
  async (
    { useCache = true, tenantId, organizationId },
    { getState, requestId, rejectWithValue },
  ) => {
    const { ciObjectDetailRequestId, loadingCiObjectDetail, ciObjectDetail } =
      getState().ci;
    if (!loadingCiObjectDetail || requestId !== ciObjectDetailRequestId) {
      return;
    }
    if (useCache && ciObjectDetail && ciObjectDetail[tenantId]) {
      return { data: { results: ciObjectDetail[tenantId] }, tenantId };
    }
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${organizationId}/tenants/${tenantId}/ci/objectDetail`,
        method: HTTP_METHODS.GET,
      }).callAuthorizedAPI();
      return { ...response.data, tenantId };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const initialState = {
  machines: {},
  userSessions: {},
  storage: {},
  spotGroups: {},
  spotGroupsUserSession: {},
  ciObjectDetail: {},
  ciObjectDetailRequestId: null,
  loadingCiObjectDetail: false,
};

const ciSlice = createSlice({
  name: "ci",
  initialState,
  reducers: {},
  extraReducers: {
    [getCiObjectInfo.pending]: (state, action) => {
      state.loadingCiObjectDetail = true;
      state.ciObjectDetailRequestId = action.meta.requestId;
    },
    [getCiObjectInfo.fulfilled]: (state, action) => {
      state.ciObjectDetailRequestId = null;
      state.loadingCiObjectDetail = false;
      state.ciObjectDetail[action.payload?.tenantId] =
        action.payload?.data?.results || [];
    },
    [getCiObjectInfo.rejected]: (state, action) => {
      state.ciObjectDetailRequestId = null;
      state.error = action.payload || true;
      state.loadingCiObjectDetail = false;
    },
  },
});

export default ciSlice.reducer;
