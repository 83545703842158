import RTKbaseQuery from "@/common/servicers/RTKbaseQuery";
import { createSelector, createSlice } from "@reduxjs/toolkit";
import { createApi } from "@reduxjs/toolkit/query/react";
import { HTTP_METHODS } from "../common/constants";
import { getIdentityId } from "../common/utils";
import { transformRegionResponse } from "./onboardingUtils";

export const azureRegionsApi = createApi({
  reducerPath: "azureRegionsApi",
  baseQuery: RTKbaseQuery,
  endpoints: (builder) => ({
    getAzureRegions: builder.query({
      query: ({ organizationId, siteType }) => {
        const endpoint = siteType
          ? `/organizations/${getIdentityId(organizationId)}/tenants/sites/${siteType}/locations`
          : `/organizations/locations/${getIdentityId(organizationId)}`;

        return {
          url: endpoint,
          method: HTTP_METHODS.GET,
        };
      },
      transformResponse: (data) => {
        if (!data) {
          return {
            azureRegions: [],
            geographies: [],
          };
        }

        const geographies = transformRegionResponse(data);

        return {
          azureRegions: data || [],
          geographies: Array.isArray(geographies) ? geographies : [],
        };
      },
      providesTags: ["AzureRegions"],
    }),
  }),
});

export const { useGetAzureRegionsQuery, useLazyGetAzureRegionsQuery } =
  azureRegionsApi;

const azureRegionsSlice = createSlice({
  name: "azureRegions",
  initialState: {
    selectedRegion: null,
  },
  reducers: {
    setSelectedRegion: (state, action) => {
      state.selectedRegion = action.payload;
    },
  },
});

export const { setSelectedRegion } = azureRegionsSlice.actions;
export default azureRegionsSlice.reducer;

export const selectAzureRegions = createSelector(
  (state) => state.azureRegionsApi.queries,
  (queries) => {
    const queryKeys = Object.keys(queries || {});
    for (const key of queryKeys) {
      const query = queries[key];
      if (query.status === "fulfilled" && query.data) {
        return query.data.azureRegions || [];
      }
    }
    return [];
  },
);

export const selectGeographies = createSelector(
  (state) => state.azureRegionsApi.queries,
  (queries) => {
    const queryKeys = Object.keys(queries || {});
    for (const key of queryKeys) {
      const query = queries[key];
      if (query.status === "fulfilled" && query.data) {
        return query.data.geographies || [];
      }
    }
    return [];
  },
);
