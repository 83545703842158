import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isModalToDeleteOpen: false,
};

export const headerSlice = createSlice({
  name: "header",
  initialState,
  reducers: {
    setIsModalToDeleteOpen(state, action) {
      state.isModalToDeleteOpen = action.payload;
    },
  },
});

export const { setIsModalToDeleteOpen } = headerSlice.actions;
