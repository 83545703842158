import { ACTIONS, FEATURES, useHasPermission } from "@/hooks/useHasPermission";

export const useGatewayPermissions = () => {
  const canCreate = useHasPermission(FEATURES.GATEWAYS, ACTIONS.CREATE);
  const canUpdate = useHasPermission(FEATURES.GATEWAYS, ACTIONS.UPDATE);
  const canDelete = useHasPermission(FEATURES.GATEWAYS, ACTIONS.DELETE);
  const canView = useHasPermission(FEATURES.GATEWAYS, ACTIONS.READ);

  return {
    canCreate,
    canDelete,
    canView,
    canUpdate,
  };
};
