import InfoIcon from "@mui/icons-material/Info";
import { FormControlLabel, FormLabel } from "@mui/material";
import { styled } from "@mui/system";
import { Checkbox } from "@veneer/core";
import * as React from "react";
import Tooltip from "../Tooltip";

const StyledFormControlLabel = styled(FormControlLabel)(() => ({
  "& .MuiFormControlLabel-label": {
    fontWeight: "normal",
  },
  "& .MuiCheckbox-root": {
    padding: 0,
  },
  "& .MuiSvgIcon-root": {
    paddingTop: "0px",
    height: "14px",
    width: "20px",
  },
  "& .MuiInputBase-root.Mui-disabled > input": {
    cursor: "not-allowed",
  },
}));

const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  marginTop: "6px",
  "&.Mui-focused": { color: "#4D52E6" },
  "&.Mui-error": { color: "#4D52E6" },
  "& .MuiSvgIcon-root": {
    fontSize: "inherit",
    marginLeft: theme.spacing(1),
  },
}));

export default function CheckboxField(props) {
  const [checked, setChecked] = React.useState(false);
  const {
    label,
    name,
    color,
    defaultChecked,
    onChange,
    input,
    meta,
    toolTip,
    disabled,
    ...rest
  } = props;

  const handleChange = (event) => {
    if (input && input.onChange) {
      input.onChange(event);
    }
    setChecked(event.target.checked);
    if (onChange) {
      onChange(event.target.checked);
    }
  };

  React.useEffect(() => {
    setChecked(defaultChecked);
  }, [defaultChecked]);

  return (
    <StyledFormControlLabel
      control={
        <Checkbox
          disabled={disabled}
          checked={checked}
          name={name}
          color={color}
          sx={{
            "& .MuiSvgIcon-root": {
              fontSize: "1.5rem",
              width: "1em",
              height: "1em",
            },
          }}
          {...input}
          {...rest}
          onChange={handleChange}
        />
      }
      label={
        <StyledFormLabel
          component="legend"
          error={meta && meta.touched && meta.error ? true : false}
        >
          {label}
          {toolTip ? (
            <Tooltip
              data-testid="test-tooltip"
              content={toolTip.title}
              placement="trailing-end"
            >
              {toolTip.component || <InfoIcon />}
            </Tooltip>
          ) : (
            ""
          )}
        </StyledFormLabel>
      }
    />
  );
}
