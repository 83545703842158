import { countriesApi } from "@/registration/countriesSliceAPI";
import { statesApi } from "@/registration/statesSlice";
import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { logger } from "redux-logger";
import adminActionReducer from "./admin-actions/adminActionsSlice";
import conductorsReducer from "./admin-actions/conductors/conductorsSlice";
import { groupsAssignmentApi } from "./admin-actions/pools/queries/groupsAssignment";
import authReducer from "./common/authSlice";
import ciSliceReducer from "./common/ciSlice";
import identitiesReducer from "./common/identitiesSlice";
import notificationReducer from "./common/notificationSlice";
import partnerReducer from "./common/partnersSlice";
import permissionsReducer from "./common/permissionsSlice";
import rolesReducer from "./common/rolesSlice";
import sidebarReducer from "./common/sidebarSlice";
import ctaReducer from "./common/slices/ctaSlice";
import userPreferencesReducer from "./common/slices/userPreferencesSlice";
import { isDevelopment } from "./common/utils";
import { moveMachinesApi } from "./dashboard/components/pools/queries/moveMachinesApi";
import dashboardReducer from "./dashboard/slices";
import { logsApi } from "./dashboard/slices/rtkLogSlice";
import { gatewaysApi } from "./features/gateways/slice/gatewaysSliceAPI";
import notificationMiddleware from "./middleware/notificationMiddleware";
import azureRegionReducer, {
  azureRegionsApi,
} from "./onboarding/azureRegionsSlice";
import onboardingReducer from "./onboarding/onboardingSlice";
import { orderSummaryApi } from "./order-summary/orderSummarySlice";
import { organizationIdentityApi } from "./registration/organizationIdentitySlice";
import { organizationsApi } from "./registration/queries/organizations";
import { headerSlice } from "./stores/slices/headerSlice";

const store = configureStore({
  reducer: {
    identities: identitiesReducer,
    onboarding: onboardingReducer,
    azureRegions: azureRegionReducer,
    partners: partnerReducer,
    auth: authReducer,
    dashboard: dashboardReducer,
    adminActions: adminActionReducer,
    ci: ciSliceReducer,
    orgRoles: rolesReducer,
    userPreferences: userPreferencesReducer,
    permissions: permissionsReducer,
    sidebar: sidebarReducer,
    cta: ctaReducer,
    notifications: notificationReducer,
    conductors: conductorsReducer,
    [headerSlice.name]: headerSlice.reducer,
    [countriesApi.reducerPath]: countriesApi.reducer,
    [gatewaysApi.reducerPath]: gatewaysApi.reducer,
    [orderSummaryApi.reducerPath]: orderSummaryApi.reducer,
    [logsApi.reducerPath]: logsApi.reducer,
    [groupsAssignmentApi.reducerPath]: groupsAssignmentApi.reducer,
    [moveMachinesApi.reducerPath]: moveMachinesApi.reducer,
    [organizationIdentityApi.reducerPath]: organizationIdentityApi.reducer,
    [azureRegionsApi.reducerPath]: azureRegionsApi.reducer,
    [statesApi.reducerPath]: statesApi.reducer,
    [organizationsApi.reducerPath]: organizationsApi.reducer,
  },
  devTools: isDevelopment(),
  middleware: (getDefaultMiddleware) => {
    let middleware = getDefaultMiddleware();

    if (isDevelopment()) {
      middleware = middleware.concat(logger);
    }

    middleware = middleware.concat(orderSummaryApi.middleware);
    middleware = middleware.concat(logsApi.middleware);
    middleware = middleware.concat(gatewaysApi.middleware);
    middleware = middleware.concat(groupsAssignmentApi.middleware);
    middleware = middleware.concat(moveMachinesApi.middleware);
    middleware = middleware.concat(organizationIdentityApi.middleware);
    middleware = middleware.concat(countriesApi.middleware);
    middleware = middleware.concat(azureRegionsApi.middleware);
    middleware = middleware.concat(statesApi.middleware);
    middleware = middleware.concat(organizationsApi.middleware);

    // Integrate the notification middleware
    middleware = middleware.concat(notificationMiddleware);

    return middleware;
  },
});

setupListeners(store.dispatch);

export default store;
