export const AD_DOMAIN_CONTROLLER_USAGE_TYPES = {
  NEW: "NewActiveDirectory",
  EXISTING: "ExistingActiveDirectory",
  EXISTING_AZURE_AD: "ExistingAzureADServices",
  AZURE_AD_DIRECT_JOIN: "AzureADDirectJoin",
};

export const NEW_AD_TYPE = {
  displayName: "Deploy New Active Directory DC in AME (coming soon)",
  type: "NewActiveDirectory",
  disabled: true,
};

export const EXISTING_NETWORK_WITH_AD_SERVICES = {
  displayName: "Connect to Existing Azure AD Domain Services",
  type: "ExistingAzureADServices",
  disabled: false,
};

export const REPLACEMENT_STRINGS = {
  "Azure AD Direct Join": "Connect to Azure AD Join (Preview)",
};
export const REGION_RECOMMENDED = "Recommended";

export const DEFAULT_LOADER_TEXT = "Please Wait ...";

export const INITIALIZATION_LOGS = "Initialization Logs";

export const DEPLOYMENT_LOGS = "Deployment Logs";

export const DISCOVERY_LOGS = "Health Check Logs";

export const PEERING_VIRTUAL_NETWORK_LOGS = "Peering Virtual Network Logs";

export const INITIALIZING_STATUS = "Initializing";
export const FINALIZING_STATUS = "Finalizing";
export const DEPLOYMENT_INPROGRESS = "Deployment In Progress";
export const DEPLOYMENT_FAILED_STATUS = "Deployment Failed";
export const DEPLOYMENT_AVAILABLE_STATUS = "Available";
export const PENDING_PEER_VIRTUAL_NETWORK = "Pending Peer Virtual Networks";
export const PEER_VIRTUAL_NETWORK_FAILED = "Peer Virtual Networks Failed";
export const PEERING_VIRTUAL_NETWORK = "Peering Network";
export const PENDING_DEPLOYMENT = "Pending Deployment";
export const DISCOVERY_IN_PROGRESS = "Discovery In Progress";

// Spot document links
export const AD_CONNECT =
  "https://docs.spot.io/spot-pc/tutorials/install-ad-connect";
export const SPOT_CLOUD_PC =
  "https://docs.spot.io/spot-pc/features/concepts/spot-pc-and-cloud-pc";
export const DEPLOY_SPOT_PC =
  "https://docs.spot.io/spot-pc/tutorials/deploy-spot-pc";
export const DEPLOY_WINDOWS_365_CLOUD_PC =
  "https://docs.spot.io/spot-pc/tutorials/deploy-windows-365-cloud-pc";

// Workforce Experience link
export const WORKFORCE_EXPERIENCE_URL =
  "https://workforceexperience.hp.com/index.html";
