import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import { Checkbox } from "@veneer/core";
import { nth } from "lodash";
import React from "react";
import { FULL_ACCESS_PERMISSION, PERMISSIONS_TYPE } from "../common/constants";
import {
  LOG_ANALYTICS_RESOURCE_TYPE,
  RESOURCE_DETAILS_HEADINGS,
} from "./dashboardConstants";

export const filterActiveMachines = (machines) =>
  machines?.filter((machine) => machine?.properties.online);

export const getMachineName = (machine) =>
  machine.split(".")[0].toLowerCase() || machine.toLowerCase();

export const getSiteName = (machineId) => nth(machineId.split("/"), -3);

const getResourceDetailsHeading = (title) => {
  if (RESOURCE_DETAILS_HEADINGS[title.toLowerCase()]) {
    return RESOURCE_DETAILS_HEADINGS[title.toLowerCase()];
  } else {
    return title;
  }
};

export const getResourceDetailsFromResourceList = (list) => {
  try {
    const resourceList = list.filter(
      (resource) => resource.type !== LOG_ANALYTICS_RESOURCE_TYPE,
    );
    const affectedResource = resourceList[0];
    const resourceDetails = {};
    const resourceIdParts = affectedResource.azureResourceId.split("/");
    for (let i = 1; i < resourceIdParts.length; i = i + 2) {
      resourceDetails[getResourceDetailsHeading(resourceIdParts[i])] =
        resourceIdParts[i + 1];
    }
    return resourceDetails;
  } catch (e) {
    return {};
  }
};

export const getAdminColumns = (editing) => {
  const columns = [
    {
      header: "Module Name",
      accessor: "displayName",
      id: "name",
    },
  ];
  if (editing) {
    columns.push({
      header: "Full Access",
      accessor: "name",
      id: "fullAccess",
      cell: (row) => {
        return (
          <Checkbox
            style={{ padding: 0 }}
            onClick={(event) =>
              row.helperData.onChange(
                event.target.checked,
                row.name,
                FULL_ACCESS_PERMISSION,
                row.helperData,
              )
            }
            data-testid={"roles-checkbox"}
            checked={
              row.helperData?.modules?.find((m) => m.key === row.name)?.actions
                ?.length ===
              row.helperData.allPermissionModules[row.name]?.actions?.length
            }
          />
        );
      },
    });
  }
  columns.push(
    ...Object.keys(PERMISSIONS_TYPE).map((permissionType) => {
      return {
        header: permissionType,
        accessor: "actions",
        id: permissionType,
        cell: (row) => {
          if (row.actions.indexOf(PERMISSIONS_TYPE[permissionType]) === -1) {
            return <></>;
          }
          const permission = row.helperData.modules?.find(
            (m) => m.key === row.key,
          );
          if (row.helperData.editing) {
            return (
              <Checkbox
                color="info"
                style={{ padding: 0 }}
                onClick={(event) =>
                  row.helperData.onChange(
                    event.target.checked,
                    row.name,
                    permissionType,
                    row.helperData,
                  )
                }
                checked={
                  permission &&
                  permission.actions.length &&
                  permission.actions?.indexOf(
                    PERMISSIONS_TYPE[permissionType],
                  ) > -1
                    ? true
                    : false
                }
              />
            );
          }
          if (permission) {
            return permission.actions.indexOf(
              PERMISSIONS_TYPE[permissionType],
            ) > -1 ? (
              <DoneIcon style={{ color: "#4D52E6" }} />
            ) : (
              <CloseIcon style={{ color: "#FF0000" }} />
            );
          } else {
            return <CloseIcon style={{ color: "#FF0000" }} />;
          }
        },
      };
    }),
  );
  return columns;
};
