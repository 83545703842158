import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import AxiosInterceptor from "./common/components/AxiosInterceptor";
import ParentRoute from "./common/components/ParentRoute";
import { AuthProvider } from "./common/contexts/AuthContext";
import appRoutes from "./routes";
// import ZendeskChatbot from "./common/components/ZendeskChatbot";
import NotificationManager from "./common/components/NotificationManager";
import NotificationsSocket from "./common/components/NotificationsSocket";

export default function App() {
  return (
    <AuthProvider>
      <Router>
        <AxiosInterceptor>
          {/* <ZendeskChatbot> */}
          <NotificationManager />
          <NotificationsSocket>
            <ParentRoute routes={appRoutes} hideAnnouncements={true} />
          </NotificationsSocket>
          {/* </ZendeskChatbot> */}
        </AxiosInterceptor>
      </Router>
    </AuthProvider>
  );
}
