import _ from "lodash";

const checkEquals = (value, expected) => _.eq(value, expected);

const checkPropertyEquals = (state, property, expected) => {
  return checkEquals(_.get(state, property), expected);
};

export const isStatusPending = (state, property) => {
  return checkPropertyEquals(state, property, "pending");
};

const isSameRequest = (state, property, value) => {
  return checkPropertyEquals(state, property, value);
};

export const shouldFulfill = ({
  state,
  statusProp,
  requestProp,
  requestId,
}) => {
  return (
    !isStatusPending(state, statusProp) &&
    isSameRequest(state, requestProp, requestId)
  );
};

export const shouldRequest = (status, requestId, currentId) => {
  const state = {
    status,
    request: requestId,
  };

  return (
    isStatusPending(state, "status") &&
    isSameRequest(state, "request", currentId)
  );
};
