import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { HTTP_METHODS } from "../../common/constants";
import HTTPClient from "../../common/servicers/httpClient";

export const getLicenses = createAsyncThunk(
  "getLicenses",
  async (
    { useCache = true, selectedTenant, organizationId },
    { getState, requestId, rejectWithValue },
  ) => {
    const { currentRequestId, loadingLicense, licenses } =
      getState().adminActions.spotGroup;
    if (!loadingLicense || requestId !== currentRequestId) {
      return;
    }
    if (useCache && licenses && licenses[selectedTenant]) {
      return { data: licenses[selectedTenant], selectedTenant };
    }
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${organizationId}/tenants/${selectedTenant}/licenses`,
        method: HTTP_METHODS.GET,
      }).callAuthorizedAPI();
      return { ...response.data, selectedTenant };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getOrgLicenses = createAsyncThunk(
  "getOrgLicenses",
  async (
    { useCache = true, organizationId },
    { getState, requestId, rejectWithValue },
  ) => {
    const {
      getOrgLicensesRequestId,
      loadingOrgLicense,
      licenses,
      licensesForOrgAvailable,
    } = getState().adminActions.spotGroup;
    if (!loadingOrgLicense || requestId !== getOrgLicensesRequestId) {
      return;
    }
    if (useCache && licenses && licensesForOrgAvailable[organizationId]) {
      return { data: licenses };
    }
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${organizationId}/licenses`,
        method: HTTP_METHODS.GET,
      }).callAuthorizedAPI();
      return { ...response.data, organizationId };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

const initialState = {
  licenses: {},
  loadingLicense: false,
  licensesForOrgAvailable: {},
  loadingOrgLicense: null,
  getOrgLicensesRequestId: null,
  totalLicenseCount: {},
};

export const spotGroupSlice = createSlice({
  name: "spotGroup",
  initialState,
  reducers: {
    setSelectedSpotGroup: (state, action) => {
      state.selectedSpotGroup = action.payload.selectedSpotGroup;
    },
    resetSelectedSpotGroup: (state) => {
      state.selectedSpotGroup = null;
    },
    resetSpotGroupErrors: (state) => {
      state.deleteSpotGroupError = null;
      state.editSpotGroupError = null;
      state.updateSpotGroupError = null;
      state.createStatus = null;
      state.assignUserError = null;
      state.assignUserSuccess = null;
      state.deleteScheduleError = null;
      state.deleteScheduleSuccess = null;
    },
    clearSpotGroups: (state, action) => {
      if (action.payload !== undefined && action.payload.tenantId) {
        if (!action.payload.silentRefresh) {
          state.spotGroups[action.payload.tenantId] = [];
        } else {
          state.silentRefresh = true;
        }
        state.refreshSpotGroups = true;
        state.spotGroupsAvailable[action.payload.tenantId] = false;
      }
    },
  },
  extraReducers: {
    [getLicenses.pending]: (state, action) => {
      state.loadingLicense = true;
      state.currentRequestId = action.meta.requestId;
      state.error = null;
    },
    [getLicenses.fulfilled]: (state, action) => {
      state.currentRequestId = null;
      state.loadingLicense = false;
      if (action.payload.selectedTenant && action.payload?.data?.licenses) {
        const data = action.payload?.data?.licenses.map((license) => ({
          ...license,
          value: license.subscriptionId,
          name: license.subscriptionName,
        }));
        _.set(state.licenses, `${action.payload.selectedTenant}`, data);
      }
    },
    [getLicenses.rejected]: (state, action) => {
      state.currentRequestId = null;
      state.error = action.payload;
      state.loadingLicense = false;
    },
    [getOrgLicenses.pending]: (state, action) => {
      state.loadingOrgLicense = true;
      state.getOrgLicensesRequestId = action.meta.requestId;
      state.error = null;
    },
    [getOrgLicenses.fulfilled]: (state, action) => {
      state.getOrgLicensesRequestId = null;
      state.loadingOrgLicense = false;
      if (action.payload.organizationId && action.payload?.data) {
        const tenantListWithLicenses = action.payload.data;
        let totalLicenseQty = 0;
        tenantListWithLicenses.forEach((tenant) => {
          const tenantId = tenant.tenantId;
          const licenses = tenant.licenses.map((license) => ({
            ...license,
            value: license.subscriptionId,
            name: license.subscriptionName,
          }));
          state.licenses[tenantId] = licenses;
          totalLicenseQty += tenant.totalUserQTY;
        });
        state.totalLicenseCount[action.payload.organizationId] =
          totalLicenseQty;
        state.licensesForOrgAvailable[action.payload.organizationId] = true;
      }
    },
    [getOrgLicenses.rejected]: (state, action) => {
      state.getOrgLicensesRequestId = null;
      state.error = action.payload;
      state.loadingOrgLicense = false;
    },
  },
});
