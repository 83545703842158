import ErrorIcon from "@/assets/svg/error";
import HPAnywareIcon from "@/assets/svg/hp-anyware-logo";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import VeneerButton from "./VeneerButton";

const PREFIX = "ErrorPage";

const classes = {
  content: `${PREFIX}-content`,
  root: `${PREFIX}-root`,
  iconStyle: `${PREFIX}-iconStyle`,
  descriptionStyle: `${PREFIX}-descriptionStyle`,
};

const Root = styled("div")((tokens) => ({
  [`&.${classes.root}`]: {
    padding: "40px",
    height: "100%",
    background: tokens.theme.palette.background.container,
    borderColor: tokens.theme.palette.background.container,
    boxShadow: "none",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "space-between",
    left: "0px",
    right: "0px",
    gap: "24px",
    color: tokens.theme.palette.foreground.default,
  },
  [`& .${classes.content}`]: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    gap: "24px",
  },

  [`& .${classes.descriptionStyle}`]: {
    textAlign: "center",
    fontSize: "18px",
    LineHeight: "24px",
    fontWeight: 400,
    maxWidth: "500px",
  },
}));

export default function ErrorPage() {
  return (
    <Root className={classes.root}>
      <div />
      <div className={classes.content}>
        <ErrorIcon />
        <h3>Hmm... something’s wrong</h3>
        <p className={classes.descriptionStyle}>
          There’s a problem on our end. We’re working on it now, please try
          again later.
        </p>
        <VeneerButton
          appearance="secondary"
          onClick={() => {
            window.history.back();
            setTimeout(() => {
              window.location.reload();
            }, 100);
          }}
        >
          Back
        </VeneerButton>
      </div>
      <HPAnywareIcon />
    </Root>
  );
}

ErrorPage.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};
