import AzureADAuthWrapper from "@/azureB2C/components/login";
import { useGetOrganizationIdentityQuery } from "@/registration/organizationIdentitySlice";
import { setItem } from "@/storage";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useAuthContext } from "../contexts/AuthContext";
import { setIdentity } from "../identitiesSlice";
import { setPartner } from "../partnersSlice";
import { getTenantPermissions } from "../permissionsSlice";

function AlertNotificationRoute() {
  const query = useLocation().search;
  const history = useHistory();
  const queryObject = new URLSearchParams(query);
  const redirectUri = queryObject.get("redirectUri");
  const { state: authData } = useAuthContext();
  const sub = authData?.account?.idTokenClaims?.sub;
  const { tenantId, orgId } = useParams();
  const dispatch = useDispatch();

  const {
    data: organizationIdentity,
    error,
    isLoading,
  } = useGetOrganizationIdentityQuery({
    organizationId: "/organizations/" + orgId,
  });

  useEffect(() => {
    if (
      authData.isAuthenticated &&
      !isLoading &&
      !error &&
      organizationIdentity
    ) {
      const org = "/organizations/" + orgId;
      setItem(sub + "org", org);
      dispatch(setIdentity(org));
      dispatch(setPartner("/tenants/" + tenantId));
      dispatch(
        getTenantPermissions({
          useCache: true,
          selectedTenantId: tenantId,
          selectedOrganizationId: orgId,
        }),
      );
      history.push(redirectUri);
    }
  }, [
    authData.isAuthenticated,
    isLoading,
    error,
    organizationIdentity,
    sub,
    orgId,
    tenantId,
    redirectUri,
    dispatch,
    history,
  ]);
  return <AzureADAuthWrapper noDisplay={true} />;
}
export default AlertNotificationRoute;
