import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { HTTP_METHODS } from "../../common/constants";
import HTTPClient from "../../common/servicers/httpClient";
import { getTenantId, queryString } from "../../common/utils";
import {
  isStatusPending,
  shouldFulfill,
  shouldRequest,
} from "../../common/utils/slices";

/**************************************
 *********** API ACTIONS ***************
 ***************************************/
//TODO: revisit pagination after backend is implemented
export const getUserSessions = createAsyncThunk(
  "getUserSessions",
  async (
    {
      useCache = true,
      organizationId,
      tenantId,
      search = "sessionState=In-Session",
      // orderBy,
      store = true,
    },
    { getState, requestId, rejectWithValue },
  ) => {
    const { fetchRequestId, fetchStatus, userSessions } =
      getState().dashboard.userSessions;

    if (!shouldRequest(fetchStatus, fetchRequestId, requestId)) {
      return;
    }
    if (useCache && userSessions && userSessions[tenantId]) {
      return { data: userSessions[tenantId], tenantId, cachedData: true };
    }
    let params = {};
    if (store) {
      params = {
        ...params,
        // pageSize: offset,
        // pageNumber: 1,
      };
      if (search) {
        params = { ...params, Search: search };
      }
      // if (orderBy) {
      //   params = { ...params, orderBy: orderBy };
      // }
    }
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${organizationId}/tenants/${tenantId}/users/pcoipSessionHistory?${queryString(params)}`,
        method: HTTP_METHODS.GET,
      }).callAuthorizedAPI();
      return { ...response.data, tenantId };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const logoutUserFromMachine = createAsyncThunk(
  "logoutUserFromMachine",
  async (
    {
      organizationId,
      tenantId,
      pcoipSessionId,
      virtualMachineName,
      userName,
      notificationMessage,
      secondsBeforeSend,
    },
    { getState, requestId, rejectWithValue },
  ) => {
    const { loggingOutRequestId, loggingOut } =
      getState().dashboard.userSessions;
    if (!loggingOut || requestId !== loggingOutRequestId) {
      return;
    }
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${organizationId}/tenants/${tenantId}/virtualMachines/${virtualMachineName}/logout`,
        method: HTTP_METHODS.POST,
        data: { userName, notificationMessage, secondsBeforeSend },
      }).callAuthorizedAPI();
      return {
        data: response.data,
        tenantId,
        pcoipSessionId,
        virtualMachineName,
        userName,
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const initialState = {
  userSessions: {},
  loggedOffSessionsIds: [],
  loading: false,
  error: null,
  currentRequestId: null,
  loggingOut: null,
  loggingOutRequestId: null,
  loggingOutError: null,
  loggedOutSuccessfully: false,
  fetchStatus: "idle",
  fetchRequestId: null,
  silentRefresh: false,
  refreshTimeOutId: null,
};

export const userSessionsSlice = createSlice({
  name: "userSessions",
  initialState,
  reducers: {
    setSilentRefresh: (state, action) => {
      state.silentRefresh = action.payload.data;
    },
    resetErrors: (state) => {
      state.error = null;
      state.loggingOutError = null;
      state.loggedOutSuccessfully = false;
    },

    updateUserSessionStatus: (state) => {
      state.silentRefresh = true;
    },
  },
  extraReducers: {
    [getUserSessions.pending]: (state, action) => {
      if (isStatusPending(state, "fetchStatus")) {
        return;
      }

      state.fetchStatus = "pending";
      state.loading = true;
      state.fetchRequestId = action.meta.requestId;
    },
    [getUserSessions.fulfilled]: (state, action) => {
      const { requestId } = action.meta;

      if (!shouldFulfill(state, "fetchStatus", "fetchRequestId", requestId)) {
        return;
      }

      state.fetchStatus = "idle";
      state.fetchRequestId = null;
      state.loading = false;
      state.error = null;
      const formatRows = (payload) => {
        return payload.map((item) => {
          return {
            machineName: item.machineName,
            loggedInUsers: item.loggedInUsers[0] ?? "",
            userName: item.userName,
            approximateDurationInMs: item.approximateDurationInMs,
            startedOn: item.startedOn,
            lastUpdatedOn: item.lastUpdatedOn,
            sessionState: item.sessionState,
            pcoipSessionId: item.pcoipSessionId,
            machineId: item.machineId,
          };
        });
      };

      const data = Array.isArray(action.payload?.data)
        ? formatRows(action.payload.data)
        : [];

      state.loggedOffSessionsIds = state.loggedOffSessionsIds.filter(
        (pcoipSessionId) =>
          data.find((session) => session.pcoipSessionId === pcoipSessionId),
      );

      _.set(state.userSessions, `${action.payload?.tenantId ?? ""}`, data);
      state.silentRefresh = false;
    },
    [getUserSessions.rejected]: (state, action) => {
      state.fetchRequestId = null;
      state.error = action.payload;
      state.loading = false;
      state.fetchStatus = "idle";
    },

    [logoutUserFromMachine.pending]: (state, action) => {
      state.loggingOut = true;
      state.loggingOutRequestId = action.meta.requestId;
      state.loggingOutError = null;
    },
    [logoutUserFromMachine.fulfilled]: (state, action) => {
      state.loggingOutRequestId = null;
      state.loggingOut = false;
      state.loggingOutError = null;
      state.loggedOutSuccessfully = true;
      state.loggedOffSessionsIds = [
        ...state.loggedOffSessionsIds,
        action.payload.pcoipSessionId,
      ];
      //TODO: the below line is commented as removing user session immediately may cause uneven results.
      //Once the logout is completed, Websocket notification will automatically remove record from table

      // state.userSessions[action.payload?.tenantId] = state.userSessions[
      //   action.payload?.tenantId
      // ].filter((s) => action.payload.virtualMachineName === s.machineName && action.payload.userName === s.userName);
    },
    [logoutUserFromMachine.rejected]: (state, action) => {
      state.loggingOutRequestId = null;
      state.loggingOutError = action.payload;
      state.loggingOut = false;
    },
  },
});

export const { resetErrors, updateUserSessionStatus } =
  userSessionsSlice.actions;

export const userSessionsSelector = (state) =>
  state.dashboard.userSessions?.userSessions[
    getTenantId(state.partners?.selectedPartner)
  ];

export default userSessionsSlice.reducer;
