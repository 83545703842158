import { ALL_CUSTOMERS } from "@/common/constants";
import { getIdentityId, getTenantId } from "@/common/utils";
import { createApi } from "@reduxjs/toolkit/query/react";
import RTKbaseQuery from "../../../common/servicers/RTKbaseQuery";

export const groupsAssignmentApi = createApi({
  reducerPath: "groupsAssignmentApi",
  baseQuery: RTKbaseQuery,
  tagTypes: ["GroupsAssignment"],
  endpoints: (builder) => ({
    assignGroupsToPool: builder.mutation({
      query: ({
        organizationId,
        tenantId,
        resourcePoolId,
        groupIds,
        action,
      }) => ({
        url: `/organizations/${organizationId}/tenants/${tenantId}/resourcePools/${resourcePoolId}/accessControl/groups`,
        method: "PUT",
        body: { groupIds, action },
      }),
      invalidatesTags: ["GroupsAssignment"],
    }),
    getPoolMembers: builder.query({
      query: ({ organizationId, tenantId, resourcePoolId, memberType }) => ({
        url:
          `/organizations/${organizationId}/tenants/${tenantId}/resourcePools/${resourcePoolId}/members` +
          `${memberType ? `?memberType=${memberType}` : ""}`,
        method: "GET",
      }),
      providesTags: ["GroupsAssignment"],
    }),
    getGroups: builder.query({
      query: ({
        selectedPartner,
        selectedIdentity,
        tenantId,
        skipToken,
        securityEnabled,
        searchTerm,
        GROUPS_API_SIZE,
      }) => {
        let getGroupsURL = null;

        if (selectedPartner !== ALL_CUSTOMERS) {
          getGroupsURL = `/organizations/${getIdentityId(selectedIdentity)}/tenants/${getTenantId(
            selectedPartner,
          )}/groups?`;
        } else {
          getGroupsURL = `/organizations/${getIdentityId(selectedIdentity)}/tenants/${tenantId}/groups?`;
        }

        let filter = "";
        if (skipToken) {
          filter = `${filter}skiptoken=${skipToken}`;
        }
        filter = `${filter}&query=`;
        filter = `${filter}${encodeURIComponent(`$top=${GROUPS_API_SIZE}&`)}`;

        if (securityEnabled) {
          filter = `${filter}${encodeURIComponent(
            `$filter=securityEnabled%20eq%20${securityEnabled} and not(startswith(displayname, 'InstallAnywareAgent')) and not(startswith(displayName, 'InstallHPAnywareOrchestrator'))&$count=true`,
          )}${encodeURIComponent("&")}`;
        }

        if (searchTerm) {
          filter = `${filter}${encodeURIComponent(`$search="displayName:${searchTerm}"`)}`;
        }

        return {
          url: `${getGroupsURL}${filter}`,
          method: "GET",
          credentials: "include",
        };
      },
      transformResponse: (response) => {
        return {
          skipToken: response.odataNextLink,
          groups: response.value.map((g) => ({
            ...g,
            name: g.displayName,
            value: g.groupId,
          })),
        };
      },
    }),
  }),
});

export const { useAssignGroupsToPoolMutation, useGetPoolMembersQuery } =
  groupsAssignmentApi;
