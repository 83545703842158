import { styled } from "@mui/material";
import { Button, Tooltip } from "@veneer/core";
import React from "react";
import { PERMISSION_DENIED_MESSAGE } from "../roles";

const commonStyles = (theme) => ({
  "&:disabled": {
    pointerEvents: "auto",
  },
  "&:hover:disabled": {
    backgroundColor: theme.palette.background.disabled,
    color: theme.palette.text.disabled,
    borderColor: "transparent",
  },
  "&:active:disabled": {
    backgroundColor: theme.palette.background.disabled,
    color: theme.palette.text.disabled,
    borderColor: "transparent",
  },
});

const IconButton = styled(Button)(({ theme }) => ({
  minWidth: "unset",
  padding: "unset",
  border: "unset",
  backgroundColor: "unset",
  "&:hover": {
    backgroundColor: "unset",
  },
  ...commonStyles(theme),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: "360px",
  ...commonStyles(theme),
}));

const VeneerButton = React.forwardRef(({ iconButton, ...other }, ref) => {
  const ButtonToRender = iconButton ? IconButton : StyledButton;

  return <ButtonToRender small ref={ref} {...other} />;
});

export const VeneerButtonWithPermissions = React.forwardRef(
  (
    {
      hasPermission = true,
      disabledReason = PERMISSION_DENIED_MESSAGE,
      iconButton,
      ...other
    },
    ref,
  ) => {
    const button = (
      <VeneerButton
        iconButton={iconButton}
        disabled={!hasPermission}
        ref={ref}
        {...other}
      />
    );

    return hasPermission ? (
      button
    ) : (
      <Tooltip content={disabledReason} placement="top-end">
        <span style={{ display: "inline-block" }}>{button}</span>
      </Tooltip>
    );
  },
);

VeneerButtonWithPermissions.displayName = "VeneerButtonWithPermissions";

VeneerButton.displayName = "VeneerButton";

export default VeneerButton;
