import AccessRequiredIcon from "@/assets/svg/access-required";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";

const PREFIX = "ErrorPage";

const classes = {
  root: `${PREFIX}-root`,
  iconStyle: `${PREFIX}-iconStyle`,
  descriptionStyle: `${PREFIX}-descriptionStyle`,
};

const Root = styled("div")((tokens) => ({
  [`&.${classes.root}`]: {
    height: "100%",
    background: tokens.theme.palette.background.container,
    borderColor: tokens.theme.palette.background.container,
    boxShadow: "none",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    left: "0px",
    right: "0px",
    gap: "24px",
    color: tokens.theme.palette.foreground.default,
  },

  [`& .${classes.descriptionStyle}`]: {
    textAlign: "center",
    fontSize: "18px",
    LineHeight: "24px",
    fontWeight: 400,
    maxWidth: "500px",
  },
}));

export default function ErrorAuth({
  title = "Access Required",
  description = "You don’t have permission to view this page or data. To request access, please contact your IT Administrator.",
}) {
  return (
    <Root className={classes.root}>
      <AccessRequiredIcon />
      <h3>{title}</h3>
      <p className={classes.descriptionStyle}>{description}</p>
    </Root>
  );
}

ErrorAuth.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};
