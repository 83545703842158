import { HTTP_METHODS } from "@/common/constants";
import RTKbaseQuery from "@/common/servicers/RTKbaseQuery";
import { createApi } from "@reduxjs/toolkit/query/react";

export const organizationIdentityApi = createApi({
  reducerPath: "organizationApi",
  baseQuery: RTKbaseQuery,
  endpoints: (builder) => ({
    getOrganizationIdentity: builder.query({
      query: (organizationId) => {
        const orgId = organizationId.replace("/organizations/", "");
        return {
          url: `/organizations/identity/${encodeURIComponent(orgId)}`,
          method: HTTP_METHODS.GET,
        };
      },
    }),
  }),
});

export const { useGetOrganizationIdentityQuery } = organizationIdentityApi;
