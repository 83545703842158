import { Box, Grid, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUsers } from "../../admin-actions/pools/poolsSlice";
import {
  DEFAULT_HOLDING_TIME,
  OPTIONS_PERSISTENT_VALUES,
} from "../../common/constants";
import { useOrgAndTenantId } from "../../common/hooks/useOrgAndTenantId";
import SelectGroupsPagination from "../../common/SelectGroupsPaginated";
import { getTenantId } from "../../common/utils";
import AssignmentUsersForm from "./AssignmentUsersForm";
import PoolHoldingTimeForm from "./PoolHoldingTimeForm";
import { RadioGroupsBox } from "./RadioGroups";

const formType = {
  targetUsers: "targetUsers",
  persist: "persistent",
  time: "time",
  entraGroups: "entraGroups",
};

const optionsPersistent = () => [
  {
    value: OPTIONS_PERSISTENT_VALUES.manual,
    title: `Persistent - ${OPTIONS_PERSISTENT_VALUES.manual}`,
    description:
      "Admins manually assign users to specific resources within the pool.",
    isChecked: false,
  },
  {
    value: OPTIONS_PERSISTENT_VALUES.automated,
    title: `Persistent - ${OPTIONS_PERSISTENT_VALUES.automated}`,
    description:
      "Users are automatically assigned to resources within the pool.",
    isChecked: false,
  },
  {
    value: OPTIONS_PERSISTENT_VALUES.floating,
    title: OPTIONS_PERSISTENT_VALUES.floating,
    description:
      "Users are automatically assigned to resources. After holding time, users are logged off, and resources become available for reassignment.",
    isChecked: false,
  },
];

export const PersistentForm = ({
  title,
  description,
  onChange,
  initialValues = {
    persistent: "",
    targetUsers: [],
    time: DEFAULT_HOLDING_TIME,
    entraGroups: [],
  },
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { loadingUsers, users } = useSelector(
    (state) => state.adminActions.pools,
  );
  const [formValues, setFormValues] = useState(initialValues);

  const { organizationId } = useOrgAndTenantId();
  const { tenantId: tenantIdOnboarding } = useSelector(
    (state) => state.onboarding,
  );

  const selectedPartner = useSelector(
    (state) => state.partners.selectedPartner,
  );
  const tenantId = getTenantId(selectedPartner);
  const currentTenant = tenantId || tenantIdOnboarding;
  useEffect(() => {
    if (currentTenant && organizationId) {
      dispatch(
        getUsers({ tenantId: currentTenant, organizationId, useCache: true }),
      );
    }
  }, [currentTenant, organizationId, dispatch]);

  const internalChange = (value, form) => {
    const newValues = { ...formValues, [form]: value };
    if (newValues.persistent === OPTIONS_PERSISTENT_VALUES.manual) {
      newValues.targetUsers = [];
      newValues.entraGroups = [];
      newValues.time = DEFAULT_HOLDING_TIME;
    }
    if (newValues.persistent === OPTIONS_PERSISTENT_VALUES.automated) {
      newValues.time = DEFAULT_HOLDING_TIME;
    }
    setFormValues(newValues);
    onChange(newValues);
  };

  const usersOptions = users[currentTenant]
    ?.filter(
      (i) => i?.userPrincipalName && !i.userPrincipalName.includes("#EXT#"),
    )
    .map((i) => ({
      value: i?.userPrincipalName,
      label: i?.displayName,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "32px" }}>
      {(title || description) && (
        <Grid item xs={12} color={theme.palette.foreground.light}>
          {title && (
            <Typography sx={{ fontSize: "18px", fontWeight: 500 }}>
              {title}
            </Typography>
          )}
          {description && <p>{description}</p>}
        </Grid>
      )}
      <Grid item xs={12}>
        <RadioGroupsBox
          defaultValue={initialValues.persistent}
          options={optionsPersistent()}
          onChange={(e) => {
            internalChange(
              e.find((item) => item.isChecked === true).value,
              formType.persist,
            );
          }}
        />
      </Grid>
      {[
        OPTIONS_PERSISTENT_VALUES.automated,
        OPTIONS_PERSISTENT_VALUES.floating,
      ].includes(formValues.persistent) && (
        <>
          <Grid item xs={12}>
            <SelectGroupsPagination
              showLicensesNotification={false}
              marginBottom="0px"
              onChange={(e) => internalChange(e, formType.entraGroups)}
              securityEnabled={true}
              onFormValidityChange={() => {}}
              label={"Entra groups (optional)"}
              formName={"Entra groups"}
              setFormValues={() => {}}
              initialValues={{
                group: initialValues?.entraGroups,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <AssignmentUsersForm
              initialValues={{
                assignmentUser: initialValues.targetUsers,
              }}
              onChange={(value) =>
                internalChange(value?.assignmentUser, formType.targetUsers)
              }
              options={usersOptions || []}
              loading={loadingUsers}
              label="Target users (optional)"
            />
          </Grid>
        </>
      )}
      {formValues.persistent === OPTIONS_PERSISTENT_VALUES.floating && (
        <Grid item xs={12}>
          <PoolHoldingTimeForm
            title="Pool holding time"
            description="Assignment holding time is how long a machine stays assigned to a user after logout. The default is 5 minutes if not set, after which the machine becomes available to others."
            initialValues={formValues?.time}
            onChange={(e) => internalChange(e, formType.time)}
          />
        </Grid>
      )}
    </Box>
  );
};
