import React from "react";
import DashBoardRouteTitle from "../../common/components/DashboardRouteTitle";
import ParentRoute from "../../common/components/ParentRoute";
import { ProtectedRouteSuspenseContainer } from "../../common/components/ProtectedRoute";
import { componentLoader } from "../../common/components/SuspenseComponent";
import {
  LAYOUTS,
  MODULES_LIST,
  PERMISSIONS_LIST,
  PERMISSIONS_TYPE,
} from "../../common/constants";

const GatewaysList = React.lazy(() =>
  componentLoader(() => import("./pages/GatewaysList")),
);

const EditGatewaysContainer = React.lazy(() =>
  componentLoader(() => import("./pages/EditGatewaysContainer")),
);

const GatewaysRoutes = [
  {
    path: "/tenant/gateways",
    authRequired: true,
    title: "Gateways",
    component: ParentRoute,
    routeTitleComponent: () => (
      <DashBoardRouteTitle title="Gateways" showQuickActions={true} />
    ),
    layoutStyle: LAYOUTS.MENU_SCREEN,
    highlightContent: true,
    routes: [
      {
        path: "/tenant/gateways",
        exact: true,
        title: "Gateways",
        component: () => (
          <ProtectedRouteSuspenseContainer component={GatewaysList} />
        ),
        permissionSet: [
          PERMISSIONS_LIST[`${MODULES_LIST.GATEWAYS}`][PERMISSIONS_TYPE.VIEW],
        ],
      },
      {
        path: "/tenant/gateways/edit/:gatewayId?",
        exact: true,
        title: "Edit",
        component: () => (
          <ProtectedRouteSuspenseContainer component={EditGatewaysContainer} />
        ),
        permissionSet: [
          PERMISSIONS_LIST[`${MODULES_LIST.GATEWAYS}`][PERMISSIONS_TYPE.VIEW],
        ],
      },
    ],
  },
];

export default GatewaysRoutes;
