import DashBoardRouteTitle from "@/common/components/DashboardRouteTitle";
import ParentRoute from "@/common/components/ParentRoute";
import { ProtectedRouteSuspenseContainer } from "@/common/components/ProtectedRoute";
import { componentLoader } from "@/common/components/SuspenseComponent";
import {
  LAYOUTS,
  MODULES_LIST,
  PERMISSIONS_LIST,
  PERMISSIONS_TYPE,
} from "@/common/constants";
import React from "react";
/*------Sites Components------*/
const SitesList = React.lazy(() =>
  componentLoader(() => import("./components/sitesList/SitesList")),
);

const EditSitesContainer = React.lazy(() =>
  componentLoader(() => import("./components/EditSitesContainer")),
);

const CreateWin365SiteContainer = React.lazy(() =>
  componentLoader(() => import("./components/win365/CreateWin365Site")),
);

const CreateVMwareSiteContainer = React.lazy(() =>
  componentLoader(() => import("./components/vmware/CreateVMwareSite")),
);

const CreateRegisteredDevicesSiteContainer = React.lazy(() =>
  componentLoader(
    () =>
      import("./components/vmRegisteredDevices/CreateRegisteredDevicesSite"),
  ),
);

const SelectServiceType = React.lazy(() =>
  componentLoader(
    () => import("../../onboarding/components/SelectServiceType"),
  ),
);

const SitesRoutes = [
  {
    path: "/sites",
    authRequired: true,
    title: "Sites",
    component: ParentRoute,
    routeTitleComponent: () => (
      <DashBoardRouteTitle title="Sites" showQuickActions={true} />
    ),
    layoutStyle: LAYOUTS.MENU_SCREEN,
    highlightContent: true,
    routes: [
      {
        path: "/sites",
        exact: true,
        title: "Sites",
        component: () => (
          <ProtectedRouteSuspenseContainer component={SitesList} />
        ),
        permissionSet: [
          PERMISSIONS_LIST[`${MODULES_LIST.SITES}`][PERMISSIONS_TYPE.VIEW],
        ],
      },
      {
        path: "/sites/action/create/win365",
        exact: true,
        title: "Creating Windows 365 Cloud PC Site",
        component: () => (
          <ProtectedRouteSuspenseContainer
            component={CreateWin365SiteContainer}
          />
        ),
        permissionSet: [
          PERMISSIONS_LIST[`${MODULES_LIST.SITES}`][PERMISSIONS_TYPE.CREATE],
        ],
      },
      {
        path: "/sites/action/create/vmware",
        exact: true,
        title: "Creating vSphere Site",
        component: () => (
          <ProtectedRouteSuspenseContainer
            component={CreateVMwareSiteContainer}
          />
        ),
        permissionSet: [
          PERMISSIONS_LIST[`${MODULES_LIST.SITES}`][PERMISSIONS_TYPE.CREATE],
        ],
      },
      {
        path: "/sites/action/create/registeredDevices",
        exact: true,
        title: "Creating Registered Devices Site",
        component: () => (
          <ProtectedRouteSuspenseContainer
            component={CreateRegisteredDevicesSiteContainer}
          />
        ),
        permissionSet: [
          PERMISSIONS_LIST[`${MODULES_LIST.SITES}`][PERMISSIONS_TYPE.CREATE],
        ],
      },
      {
        path: "/sites/action/edit/:siteId?",
        exact: true,
        title: "Edit",
        component: () => (
          <ProtectedRouteSuspenseContainer component={EditSitesContainer} />
        ),
        permissionSet: [
          PERMISSIONS_LIST[`${MODULES_LIST.SITES}`][PERMISSIONS_TYPE.VIEW],
        ],
      },
      {
        path: "/sites/selectService/:tenantId?",
        title: "Select Service for Site",
        component: () => (
          <ProtectedRouteSuspenseContainer component={SelectServiceType} />
        ),
        permissionSet: [
          PERMISSIONS_LIST[`${MODULES_LIST.SITES}`][PERMISSIONS_TYPE.EDIT],
        ],
      },
    ],
  },
];

export default SitesRoutes;
