import { HTTP_METHODS } from "@/common/constants";
import RTKbaseQuery from "@/common/servicers/RTKbaseQuery";
import { createApi } from "@reduxjs/toolkit/query/react";

export const statesApi = createApi({
  reducerPath: "statesApi",
  baseQuery: RTKbaseQuery,
  endpoints: (builder) => ({
    getStates: builder.query({
      query: () => {
        return {
          url: "/states/",
          method: HTTP_METHODS.GET,
        };
      },
    }),
  }),
});

export const { useGetStatesQuery } = statesApi;
