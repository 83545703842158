import { Box, Grid, Paper, useTheme } from "@mui/material";
import styled from "@mui/system/styled";
import { IconLogPage } from "@veneer/core";
import React from "react";
import SideProgressStyles from "../styles/side-progress-styles";
import { ProgressLogBar } from "./ProgressLogBar";
import { ProgressLogStepCard } from "./ProgressLogStepCard";

const Root = styled(Paper)(({ theme }) => ({
  width: "100%",
  padding: theme.spacing(3),
  borderRadius: "8px",
  backgroundColor: theme.palette.background.container,
  border: `1px solid ${theme.palette.background.stroke}`,
  boxShadow: "none",
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2),
  maxWidth: "432px",
}));

const IconContainer = styled("div")(({ theme }) => {
  return {
    width: "54px",
    height: "54px",
    display: "grid",
    placeItems: "center",
    borderRadius: "50%",
    backgroundColor: theme.palette.background.default,
  };
});

const SideProgress = (props) => {
  const { data, loading } = props;
  const theme = useTheme();
  const iconColor = theme.palette.primary.main;
  const textColor = theme.palette.foreground.medium;

  return (
    <SideProgressStyles style={{ position: "sticky", top: "68px" }}>
      <Root>
        <Grid container alignContent={"center"} alignItems={"center"} gap={1.5}>
          <IconContainer>
            <IconLogPage color={iconColor} size={32} />
          </IconContainer>
          <h6 style={{ fontWeight: 500 }}>Progress log</h6>
        </Grid>
        <span style={{ fontSize: "12px", fontWeight: 400, color: textColor }}>
          This progress log tracks the tasks that are currently being handled in
          the background.
        </span>

        <Box mt={2}>
          <ProgressLogBar steps={data} loading={loading} />
        </Box>

        <Box display="flex" gap={0.5} flexDirection="column" mt={2}>
          {!loading &&
            Object.keys(data).map((step, index) => (
              <ProgressLogStepCard
                key={index}
                title={data[index].step}
                currentStatus={data[index].status}
                timestamp={data[index].timestamp}
              />
            ))}
        </Box>
      </Root>
    </SideProgressStyles>
  );
};

export default SideProgress;
