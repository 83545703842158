const AccessRequiredIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="265"
    height="241"
    fill="none"
    viewBox="0 0 265 241"
  >
    <circle cx="120.214" cy="120.778" r="120" fill="#DBDDFF"></circle>
    <path
      fill="#EDEDF7"
      d="M232.117 77.357C214.717 32.545 171.175.778 120.214.778S25.711 32.545 8.311 77.357z"
    ></path>
    <path
      fill="#EDEDF7"
      d="M20.428 106.735c13.64-12.453 96.074-25.289 162.866-29.423l-.429.06c-61.258 8.633-143.497 20.223-146.858 35.626-8.767 40.187 117.573 64.878 183.252 75.411-27.108 40.188-76.406 61.57-128.623 48.743-54.629-48.743-92.368-110.183-70.208-130.417"
    ></path>
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M170.634 31.058c-19.769 0-35.795 16.026-35.795 35.795v44.508h-7.159c-7.908 0-14.319 6.41-14.319 14.318v60.653c0 7.908 6.411 14.318 14.319 14.318h85.909c7.907 0 14.318-6.41 14.318-14.318v-60.653c0-7.908-6.411-14.318-14.318-14.318h-7.159V66.853c0-19.769-16.027-35.795-35.796-35.795m28.636 80.303V66.853c0-15.815-12.82-28.636-28.636-28.636s-28.636 12.82-28.636 28.636v44.508z"
      clipRule="evenodd"
    ></path>
    <path
      stroke="#212121"
      d="M134.839 111.361V66.853c0-19.769 16.026-35.795 35.795-35.795s35.796 16.026 35.796 35.795v44.508m-71.591 0h-7.159c-7.908 0-14.319 6.41-14.319 14.318v60.653c0 7.908 6.411 14.318 14.319 14.318h85.909c7.907 0 14.318-6.41 14.318-14.318v-60.653c0-7.908-6.411-14.318-14.318-14.318h-7.159m-71.591 0h7.159m64.432 0h-7.16m0 0V66.853c0-15.815-12.82-28.636-28.636-28.636s-28.636 12.82-28.636 28.636v44.508m57.272 0h-57.272"
    ></path>
    <path
      fill="#212121"
      d="M174.213 149.771a7.159 7.159 0 1 0-7.159 0v18.856a3.58 3.58 0 0 0 7.159 0zM141.829 108.409h-6.785v3.051h6.785zM206.322 108.409h-6.785v3.051h6.785z"
    ></path>
    <path
      fill="#212121"
      d="M117.341 185.799V116.13c-2.336 2.083-2.764 3.014-3.829 8.226v64.095c1.3 6.77 7.102 11.928 14.18 12.199h86.762c4.013-.486 5.868-1.3 8.654-3.66H129.74c-5.009 0-12.399-3.009-12.399-11.191"
    ></path>
    <path
      fill="#A2A5FB"
      stroke="#A2A5FB"
      strokeLinejoin="round"
      d="m225.852 74.067-3.036 25.428 11.642-21.613zM263.9 122.116l-25.59-.951 23.159-8.143zM250.698 84.629l-18.947 25.317 26.344-16.667z"
    ></path>
    <path
      fill="#fff"
      d="M64.13 92.835h13.329c2.743 5.59 7.404 21.863 8.797 28.888s3.546 14.945 4.078 26.763c.988 21.945-.579 48.44-.579 48.44h-7.978s-2.12-40.046-2.972-45.724c-.852-5.679-13.452-45.077-13.452-45.077"
    ></path>
    <path
      stroke="#212121"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M64.13 92.835h13.329c2.743 5.59 7.404 21.863 8.797 28.888s3.546 14.945 4.078 26.763c.988 21.945-.579 48.44-.579 48.44h-7.978s-2.12-40.046-2.972-45.724c-.852-5.679-13.452-45.077-13.452-45.077"
    ></path>
    <path fill="#212121" d="m70.201 120.535.7-9.728-1.633 8.482z"></path>
    <path
      fill="#fff"
      d="M70.133 94.206H56.127c-3.723 7.783-4.546 18.752-4.546 27.516 0 3.723.922 9.442.394 24.45-.052 1.48-.538 3.472-.94 5.284-4.799 21.694-15.759 45.469-15.759 45.469h7.992l17.037-35.28c2.239-4.734 3.91-8.475 5.043-13.993l5.327-37.077"
    ></path>
    <path
      stroke="#212121"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M70.133 94.206H56.127c-3.723 7.783-4.546 18.752-4.546 27.516 0 3.723.922 9.442.394 24.45-.052 1.48-.538 3.472-.94 5.284-4.799 21.694-15.759 45.469-15.759 45.469h7.992l17.037-35.28c2.239-4.734 3.91-8.475 5.043-13.993l5.327-37.077"
    ></path>
    <path
      fill="#212121"
      stroke="#212121"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M40.871 200.543H34l1.27-3.438h7.17z"
    ></path>
    <path
      fill="#fff"
      stroke="#212121"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M48.169 207.985a6.03 6.03 0 0 0-2.522-5.238c-2.856-2.005-4.265-2.316-4.265-2.316h-7.08s-1.877 1.109-1.877 3.777v3.777z"
    ></path>
    <path
      fill="#BBBDFB"
      d="M34.942 206.304c-.815 0-1.475-.88-1.475-1.966s.66-1.966 1.474-1.966c.815 0 1.475.88 1.475 1.966s-.66 1.966-1.474 1.966"
    ></path>
    <path
      fill="#212121"
      d="M48.169 207.986H32.425v1.224a.62.62 0 0 0 .184.446.63.63 0 0 0 .447.185h14.482a.633.633 0 0 0 .63-.631z"
    ></path>
    <path
      fill="#fff"
      d="M45.65 202.748c-3.255-1.062-6.203-.081-6.93 1.462z"
    ></path>
    <path
      stroke="#212121"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M45.65 202.748c-3.255-1.062-6.203-.081-6.93 1.462"
    ></path>
    <path
      fill="#212121"
      stroke="#212121"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M89.274 201.502h-6.63l-.288-4.397h6.918z"
    ></path>
    <path
      fill="#212121"
      d="M81.765 207.986h21.13v1.224a.63.63 0 0 1-.631.631H82.396a.633.633 0 0 1-.631-.631z"
    ></path>
    <path
      fill="#fff"
      stroke="#212121"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M82.72 200.431s-1.338 1.193-1.338 3.777c.052 1.268.2 2.531.44 3.777h21.072s.566-3.55-2.205-4.73-11.358-2.824-11.358-2.824-1.093.691-3.553.691c-2.26 0-3.057-.691-3.057-.691"
    ></path>
    <path
      fill="#BBBDFB"
      d="M84.525 206.276a1.966 1.966 0 1 0 0-3.933 1.966 1.966 0 0 0 0 3.933"
    ></path>
    <path
      fill="#fff"
      d="M99.309 202.767c-2.143.48-2.187 2.781-2.187 2.781z"
    ></path>
    <path
      stroke="#212121"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M99.309 202.767c-2.143.48-2.187 2.781-2.187 2.781"
    ></path>
    <path
      fill="#fff"
      stroke="#212121"
      strokeLinecap="round"
      strokeMiterlimit="10"
      d="M76.639 59.927a4.41 4.41 0 0 1-2.89-4.139v-6.773l-10.034-.089c.22 1.38.157 3.847.086 6.923a4.415 4.415 0 0 1-2.89 4.037l-.085.03s-.132 4.69 7.77 4.69 8.042-4.68 8.042-4.68Z"
    ></path>
    <path
      fill="#7C80F4"
      d="M75.318 58.793s2.151 2.045-4.916 2.045c-7.034 0-7.824-2.34-7.824-2.34-1.095.174-13.106 2.11-8.988 8.798 0 7.973 1.994 27.255 1.994 27.255h22.838s1.517-19.282 1.517-27.255c.061-8.116-.315-6.905-4.62-8.503"
    ></path>
    <path
      fill="#fff"
      d="M54.719 110.549c-3.293 2.155-4.911-.111-5.101-.799-.324-1.174.652-3.013 1.76-3.318"
    ></path>
    <path
      fill="#fff"
      d="M54.719 110.549c-3.293 2.155-4.911-.111-5.101-.799-.324-1.174.652-3.013 1.76-3.318.013-1.147.18-1.699-.05-2.532l-8.701-7.911 3.993-4.273 6.313 7.395c1.838 1.939 3.43 4.465 3.81 5.844.612 2.224.012 4.262-2.024 5.594"
    ></path>
    <path
      stroke="#212121"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M54.719 110.549c-3.293 2.155-4.911-.111-5.101-.799-.324-1.174.652-3.013 1.76-3.318.013-1.147.18-1.699-.05-2.532l-8.701-7.911 3.993-4.273 6.313 7.395c1.838 1.939 3.43 4.465 3.81 5.844.612 2.224.012 4.262-2.024 5.594"
    ></path>
    <path
      fill="#7C80F4"
      d="M37.685 88.093a5.766 5.766 0 1 0-4.148-10.761 5.766 5.766 0 0 0 4.148 10.76"
    ></path>
    <path
      fill="#7C80F4"
      d="m39.419 87.298-6.915-9.228L54.21 61.066l7.78 12.416z"
    ></path>
    <path
      fill="#7C80F4"
      d="M56.018 71.781a5.991 5.991 0 1 0 3.172-11.555 5.991 5.991 0 0 0-3.172 11.555M30.843 86.15l8.278-8.028 9.404 13.374-5.648 5.315z"
    ></path>
    <path fill="#212121" d="m54.166 78.269 6.183-4.674-6.005 7.093z"></path>
    <path
      fill="#7C80F4"
      d="m101.424 59.786 2.275 9.892-28.886 7.297 3.326-17.205c1.45.756 3.976 1.192 4.932 1.076z"
    ></path>
    <path
      fill="#fff"
      d="m90.309 53.023 5.05-3.768c-2.343-3.127-4.805-5.19-9.28-10.522-1.694-2.017-1.253-5.22-2.285-5.877-1.032-.658-2.423-1.224-3.17-1.362-2.126-.393-3.136 1.342-2.29 2.035 1.16.5.872-1.576 3.146.212.384.295-.91-.022-1.322-.054s-1.025.023-1.3.604c-.036.16.01.282.058.404.133.331.225.53.225.53-.503.172-.57.498-.6.827s.18.695.31.823c.25.248.428.343.428.343s-.335.232-.186.637c.28.762.983.876 1.105.995.196.42.582.991.895 1.34.374.417.846.756 1.451.947.38.12.72.264.857.462 3.325 4.805 6.908 11.424 6.908 11.424"
    ></path>
    <path
      stroke="#212121"
      strokeLinejoin="round"
      d="M81.48 33.741c-2.274-1.788-1.986.288-3.146-.212-.846-.694.164-2.428 2.29-2.035.747.138 2.138.704 3.17 1.362s.591 3.86 2.284 5.877c4.476 5.331 6.938 7.395 9.281 10.522l-5.05 3.768s-3.583-6.619-6.908-11.424c-.138-.198-.476-.341-.857-.462a3.23 3.23 0 0 1-1.45-.946 6.4 6.4 0 0 1-.896-1.34c-.122-.12-.825-.234-1.105-.996-.149-.405.186-.636.186-.636s-.178-.096-.429-.344c-.129-.128-.34-.495-.31-.823.032-.33.098-.655.601-.826 0 0-.092-.2-.225-.53-.049-.123-.094-.245-.058-.405.275-.58.888-.636 1.3-.604s1.706.349 1.322.054Zm0 0c-.073-.056.243.19 0 0Z"
    ></path>
    <path
      fill="#7C80F4"
      d="m106.015 60.435-9.667 6.707-8.972-16.93 6.365-4.754z"
    ></path>
    <path
      fill="#7C80F4"
      d="M106.198 67.814c1.986-2.567 1.505-6.265-1.074-8.26-2.58-1.995-6.281-1.531-8.268 1.035-1.986 2.567-1.505 6.266 1.075 8.26 2.579 1.996 6.281 1.532 8.267-1.035"
    ></path>
    <path fill="#212121" d="m79.662 75.755.332-6.735 1.114 6.374z"></path>
    <path
      fill="#fff"
      d="M68.969 51.914c1.534 1.056 4.073 2.197 5.935 2.225 3.768.08 5.675-1.669 5.917-7.733.108-.557.298-1.641.188-2.579.01-.223.033-.613.043-.778.127-2.1.336-4.857.361-6.052.044-2.11-3.618-3.831-7.32-3.91-3.701-.077-9.385.105-9.46 3.721l-.178 8.496c.308 2.661 1.177 3.06 1.783 4.417"
    ></path>
    <path
      stroke="#212121"
      strokeLinecap="round"
      strokeMiterlimit="10"
      d="M68.969 51.914c1.534 1.056 4.073 2.197 5.935 2.225 3.768.08 5.675-1.669 5.917-7.733.108-.557.298-1.641.188-2.579.01-.223.033-.613.043-.778.127-2.1.336-4.857.361-6.052.044-2.11-3.618-3.831-7.32-3.91-3.701-.077-9.385.105-9.46 3.721l-.178 8.496c.308 2.661 1.177 3.06 1.783 4.417"
    ></path>
    <path
      fill="#212121"
      d="M77.688 43.138a.69.69 0 1 0 .029-1.38.69.69 0 0 0-.029 1.38M75.53 40.542c.033.316.73-.133 1.623-.222.893-.09 1.651.208 1.619-.107s-.812-.743-1.703-.651c-.89.092-1.57.662-1.538.98"
    ></path>
    <path
      stroke="#212121"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M79.336 49.024s-1.109-.322-2.25-.267"
    ></path>
    <path
      fill="#212121"
      d="M71.603 41.002s-.44-5.902 4.524-5.798c5.922.124 5.454 2.31 5.454 2.31.196-9.367-9.138-10.327-15.635-8.813-7.633 1.78-8.581 9.037-8.633 11.513s-.13 6.187 2.98 8.729c2.438 1.84 1.202.786 2.955 2.474 1.229 1.183 7.874-.47 8.355-10.415"
    ></path>
    <path
      fill="#fff"
      d="M81.03 43.483c-.22 1.703 1.65 3.728.662 3.933-1.495.308-1.791.114-1.791.114"
    ></path>
    <path
      stroke="#212121"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M81.03 43.483c-.22 1.703 1.65 3.728.662 3.933-1.495.308-1.791.114-1.791.114"
    ></path>
    <path fill="#fff" d="M73.5 42.407a2.262 2.262 0 1 0-1.347 2.905"></path>
    <path
      stroke="#212121"
      strokeLinecap="round"
      strokeMiterlimit="10"
      d="M73.5 42.407a2.262 2.262 0 1 0-1.347 2.905"
    ></path>
    <path fill="#fff" d="M70.924 42.488a1.32 1.32 0 0 1 1.04 1.092"></path>
    <path
      stroke="#212121"
      strokeLinecap="round"
      strokeMiterlimit="10"
      d="M70.924 42.488a1.32 1.32 0 0 1 1.04 1.092M71.63 42.884l-.573.611"
    ></path>
    <path
      fill="#212121"
      d="M86.933 35.636c-1.932 1.73-3.703 1.646-5.137 1.098-.427-.163-.743-.513-.984-.9-1.458-2.346-5.533-6.479-5.087-6.838.157-.127.397.04.584.116.45.182 1.31.582 1.873.905 1.35.773 2.777.984 4.32-.186.448-.341 2.34-2.667 4.98-1.93 2.726.76 1.683 5.735-.55 7.735"
    ></path>
  </svg>
);

export default AccessRequiredIcon;
