import ParentRoute from "@/common/components/ParentRoute";
import { ProtectedRouteSuspenseContainer } from "@/common/components/ProtectedRoute";
import { componentLoader } from "@/common/components/SuspenseComponent";
import { LAYOUTS, SUPPORT_ROLES } from "@/common/constants";
import React from "react";

const WelcomePage = React.lazy(() =>
  componentLoader(() => import("./welcomePage")),
);

const RegistrationForm = React.lazy(() =>
  componentLoader(() => import("./registrationForm")),
);

const RegistrationStepsCompleted = React.lazy(() =>
  componentLoader(() => import("./registrationStepsCompleted")),
);

const RegistrationStepsCompletedWrapper = (props) => (
  <RegistrationStepsCompleted {...props} steps={props.steps || []} />
);

const RegistrationRoutes = [
  {
    path: "/create-organization",
    authRequired: true,
    nobreadcrumbs: true,
    exact: true,
    component: ParentRoute,
    layoutStyle: LAYOUTS.FULL_SCREEN,
    roles: [...SUPPORT_ROLES],
    routes: [
      {
        path: "/create-organization",
        component: () => (
          <ProtectedRouteSuspenseContainer component={WelcomePage} />
        ),
        roles: [...SUPPORT_ROLES],
      },
    ],
  },
  {
    path: "/create-organization/form",
    authRequired: true,
    nobreadcrumbs: true,
    exact: true,
    component: ParentRoute,
    layoutStyle: LAYOUTS.FULL_SCREEN,
    roles: [...SUPPORT_ROLES],
    routes: [
      {
        path: "/create-organization/form",
        component: () => (
          <ProtectedRouteSuspenseContainer component={RegistrationForm} />
        ),
        roles: [...SUPPORT_ROLES],
      },
    ],
  },
  {
    path: "/create-organization/completed",
    authRequired: true,
    nobreadcrumbs: true,
    exact: true,
    component: ParentRoute,
    layoutStyle: LAYOUTS.FULL_SCREEN,
    roles: [...SUPPORT_ROLES],
    routes: [
      {
        path: "/create-organization/completed",
        component: () => (
          <ProtectedRouteSuspenseContainer
            component={RegistrationStepsCompletedWrapper}
          />
        ),
        roles: [...SUPPORT_ROLES],
      },
    ],
  },
];

export default RegistrationRoutes;
