import { CREDENTIAL_TYPES } from "./deschutesConstants";

export const validateChooseConnectionsStep = (values) => {
  if (!values.credentialsType) {
    return false;
  }
  if (
    values.credentialsType === CREDENTIAL_TYPES.NEW &&
    (!values.username || !values.password)
  ) {
    return false;
  }
  return true;
};

export const checkW365NotEnabled = (tenantId, cloudPcEnabled) => {
  return (
    cloudPcEnabled &&
    tenantId &&
    typeof cloudPcEnabled[tenantId] !== "undefined" &&
    !cloudPcEnabled[tenantId]
  );
};

export const checkW365Enabled = (tenantId, cloudPcEnabled) => {
  return (
    cloudPcEnabled &&
    tenantId &&
    typeof cloudPcEnabled[tenantId] !== "undefined" &&
    cloudPcEnabled[tenantId]
  );
};

export const HEALTH_CHECK_STATUS = {
  PASSED: "passed",
  FAILED: "failed",
  PENDING: "pending",
  RUNNING: "running",
  WARNING: "warning",
};
