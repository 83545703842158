import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { HTTP_METHODS } from "./constants";
import HTTPClient from "./servicers/httpClient";

export const getTenantPermissions = createAsyncThunk(
  "getTenantPermissions",
  async (
    { useCache = true, selectedTenantId, selectedOrganizationId },
    { getState, requestId, rejectWithValue },
  ) => {
    const { currentRequestId, loadingTenantPermissions, userPermissionsList } =
      getState().permissions;
    if (!loadingTenantPermissions || requestId !== currentRequestId) {
      return;
    }
    if (
      useCache &&
      userPermissionsList &&
      userPermissionsList[selectedTenantId]
    ) {
      return { data: userPermissionsList[selectedTenantId], cachedData: true };
    }
    try {
      const response = await new HTTPClient({
        endpoint: `/organizations/${selectedOrganizationId}/tenants/${selectedTenantId}/identity/permissions`,
        method: HTTP_METHODS.GET,
      }).callAuthorizedAPI();
      return { ...response.data, selectedTenantId };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const getAllPermissionModules = createAsyncThunk(
  "getAllPermissionModules",
  async ({ useCache = true }, { getState, requestId, rejectWithValue }) => {
    const {
      permissionModulesRequestId,
      loadingPermissionModules,
      allPermissionModules,
    } = getState().permissions;
    if (!loadingPermissionModules || requestId !== permissionModulesRequestId) {
      return;
    }
    if (useCache && allPermissionModules) {
      return { data: allPermissionModules, cachedData: true };
    }
    try {
      const response = await new HTTPClient({
        endpoint: "/organizations/tenants/permissions/modules",
        method: HTTP_METHODS.GET,
      }).callAuthorizedAPI();
      return { ...response.data };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const initialState = {
  loadingPermissionModules: null,
  allPermissionModules: null,
  errorLoadingPermissionModules: {},
  permissionModulesRequestId: null,
  loadingTenantPermissions: null,
  currentRequestId: null,
  errorLoadingTenantPermissions: null,
  userPermissionsList: {},
  updatingTenantPermissions: false,
  errorUpdatingTenantPermissions: null,
  tenantModules: {},
};

export const permissionsSlice = createSlice({
  name: "permissions",
  initialState,
  reducers: {},
  extraReducers: {
    [getTenantPermissions.pending]: (state, action) => {
      state.loadingTenantPermissions = true;
      state.currentRequestId = action.meta.requestId;
    },
    [getTenantPermissions.fulfilled]: (state, action) => {
      state.loadingTenantPermissions = false;
      state.currentRequestId = null;
      if (!action.payload.cachedData && action.payload?.data?.modules) {
        const modules = action.payload.data.modules;
        state.tenantModules = modules;
        state.userPermissionsList[action.payload.selectedTenantId] =
          modules.reduce((acc, currentModule) => {
            currentModule?.actions?.forEach((action) => {
              acc[`${currentModule.key}.${action}`] = true;
            });
            return acc;
          }, {});
      }
    },
    [getTenantPermissions.rejected]: (state, action) => {
      state.errorLoadingTenantPermissions = action.payload;
      state.loadingTenantPermissions = false;
      state.currentRequestId = null;
    },
    [getAllPermissionModules.pending]: (state, action) => {
      state.loadingPermissionModules = true;
      state.permissionModulesRequestId = action.meta.requestId;
      state.errorLoadingPermissionModules = null;
    },
    [getAllPermissionModules.fulfilled]: (state, action) => {
      state.loadingPermissionModules = false;
      state.permissionModulesRequestId = null;
      if (!action.payload.cachedData) {
        let allPermissions = {};
        if (action.payload.data) {
          action.payload.data.modules.forEach((module) => {
            allPermissions[module.key] = module;
          });
          state.allPermissionModules = allPermissions;
          state.allPermissionsList = action.payload.data.modules.reduce(
            (acc, currentModule) => {
              currentModule?.actions?.forEach((action) => {
                acc[`${currentModule.key}.${action}`] = true;
              });
              return acc;
            },
            {},
          );
        }
      }
      state.errorLoadingPermissionModules = null;
    },
    [getAllPermissionModules.rejected]: (state, action) => {
      state.errorLoadingPermissionModules = action.payload;
      state.loadingPermissionModules = false;
      state.permissionModulesRequestId = null;
    },
  },
});

export default permissionsSlice.reducer;
