import { ACTIONS, FEATURES, useHasPermission } from "@/hooks/useHasPermission";

export const useResourcePoolPermissions = () => {
  const canCreate = useHasPermission(FEATURES.RESOURCE_POOLS, ACTIONS.CREATE);
  const canDelete = useHasPermission(FEATURES.RESOURCE_POOLS, ACTIONS.DELETE);
  const canUpdate = useHasPermission(FEATURES.RESOURCE_POOLS, ACTIONS.UPDATE);
  const canView = useHasPermission(FEATURES.RESOURCE_POOLS, ACTIONS.READ);

  return {
    canCreate,
    canDelete,
    canUpdate,
    canView,
  };
};
