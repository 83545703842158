import { adminAccountsSlice } from "@/admin-actions/apiUsers/adminAccountsSlice";
import { apiAccountsSlice } from "@/admin-actions/apiUsers/apiAccountsSlice";
import { connectionsSlice } from "@/admin-actions/deschutes/connectionsSlice";
import {
  deschutesSlice,
  getDeviceImages,
  getDeviceImagesForSite,
  getGalleryImages,
  getGroups,
} from "@/admin-actions/deschutes/deschutesSlice";
import { poolsSlice } from "@/admin-actions/pools/poolsSlice";
import { sitesSlice } from "@/admin-actions/sites/sitesSlice";
import { spotGroupSlice } from "@/admin-actions/spotGroups/spotGroupsSlice";
import { combineReducers } from "@reduxjs/toolkit";

/*------Slices------*/
export const adminActionsSlices = {
  actions: {
    ...deschutesSlice.actions,
    ...connectionsSlice.actions,
    ...poolsSlice.actions,
    ...apiAccountsSlice.actions,
    ...adminAccountsSlice.actions,
    ...sitesSlice.actions,
  },
  reducer: combineReducers({
    deschutes: deschutesSlice.reducer,
    sites: sitesSlice.reducer,
    connections: connectionsSlice.reducer,
    spotGroup: spotGroupSlice.reducer,
    pools: poolsSlice.reducer,
    apiAccounts: apiAccountsSlice.reducer,
    adminAccounts: adminAccountsSlice.reducer,
  }),
};

/*------API Actions------*/

/*-----Deschutes Actions------*/
export { getDeviceImages, getDeviceImagesForSite, getGalleryImages, getGroups };

/*------Reducer------*/
export default adminActionsSlices.reducer;
