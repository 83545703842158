import { Grid, useTheme } from "@mui/material";
import { Field, Form } from "react-final-form";
import TextInput from "../../common/components/form/TextInput";
import {
  composeValidators,
  greaterThanZero,
  required,
} from "../../common/validators";

export default function QuantityForm({
  onChange,
  initialValues,
  form = "quantityForm",
}) {
  const theme = useTheme();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} color={theme.palette.foreground.light}>
        <p>
          Creates specified number of virtual machines using the defined
          template and configurations.
        </p>
      </Grid>
      <Grid item xs={12}>
        <Form
          onSubmit={() => {}}
          validate={(e) => onChange(e, form)}
          initialValues={initialValues}
          render={(formMeta) => {
            return (
              <form onSubmit={formMeta.handleSubmit}>
                <Field
                  name="quantity"
                  validate={composeValidators(required, greaterThanZero)}
                  render={({ input, meta }) => (
                    <TextInput
                      {...input}
                      type="number"
                      label="Number of Virtual Machines to be provisioned."
                      sx={{
                        width: "100%",
                      }}
                      error={meta.touched && meta.error}
                      helperText={meta.touched && meta.error}
                      min={1}
                    />
                  )}
                />
              </form>
            );
          }}
        />
      </Grid>
    </Grid>
  );
}
