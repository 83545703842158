import { HTTP_METHODS } from "@/common/constants";
import RTKbaseQuery from "@/common/servicers/RTKbaseQuery";
import { createApi } from "@reduxjs/toolkit/query/react";

export const organizationsApi = createApi({
  reducerPath: "organizationsApi",
  baseQuery: RTKbaseQuery,
  tagTypes: ["organizations"],
  endpoints: (builder) => ({
    createOrganization: builder.mutation({
      query: (values) => {
        const formData = new FormData();
        Object.keys(values).forEach((key) => {
          formData.append(key, values[key]);
        });

        return {
          url: "/organizations/",
          method: HTTP_METHODS.POST,
          body: formData,
        };
      },
      invalidatesTags: ["organizations"],
    }),
  }),
});

export const { useCreateOrganizationMutation } = organizationsApi;
