import { useSelector } from "react-redux";

export const FEATURES = {
  // Top-level features
  VIRTUAL_MACHINES: "VIRTUAL_MACHINES",
  USER_SESSIONS: "USER_SESSIONS",
  LOGS: "LOGS",
  MONITORS: "MONITORS",
  SITES: "SITES",
  RESOURCE_POOLS: "RESOURCE_POOLS",
  GATEWAYS: "GATEWAYS",

  // Nested features
  DASHBOARD: {
    OVERVIEW: "DASHBOARD.OVERVIEW",
    TENANT_DETAILS: "DASHBOARD.TENANT_DETAILS",
  },

  VM: {
    ROOT: "VIRTUAL_MACHINES",
    PERFORMANCE: "VIRTUAL_MACHINES.PERFORMANCE",
    PROCESSES: "VIRTUAL_MACHINES.PROCESSES",
    QUICK_ACTIONS: {
      CONNECT: "VIRTUAL_MACHINES.QUICK_ACTIONS.CONNECT_TO_MACHINE",
      REBOOT: "VIRTUAL_MACHINES.QUICK_ACTIONS.REBOOT",
    },
  },
};

export const ACTIONS = {
  CREATE: "create",
  READ: "read",
  UPDATE: "update",
  DELETE: "delete",
  ACCESS: "access",
};

export const useHasPermission = (feature, permission) => {
  const { tenantModules = [], loadingTenantPermissions = false } = useSelector(
    (state) => state.permissions || {},
  );

  if (loadingTenantPermissions) {
    return false;
  }

  if (!Array.isArray(tenantModules)) {
    return false;
  }

  const module = tenantModules.find((mod) => mod.key === feature);

  if (!module) {
    return false;
  }

  if (!Array.isArray(module.actions)) {
    return false;
  }

  return module.actions.includes(permission);
};
