/* eslint-disable react-hooks/rules-of-hooks */
import { useParams } from "react-router";

export const getUserName = (params) => {
  const { param } = params ? params : useParams();
  return param;
};

export const getTabTitle = (params) => {
  const { tab } = params ? params : useParams();

  // example: activityLogs => Activity Logs
  if (tab) {
    return tab
      .replace(/([A-Z])/g, " $1")
      .replace(/^./, (str) => str.toUpperCase());
  }

  return tab;
};
